import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, PriorityCell } from 'shared/components';
import { formatDateDistance } from 'shared/helpers';
import { useWindowDimensions } from 'shared/HOCs';
import { IconBell } from 'shared/icons';
import TicketDetails from '../TicketDetails';
import * as Styled from './styles';

const TicketsList = ({
  data,
  refreshUsers,
}) => {
  const { isMobile } = useWindowDimensions();

  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isTicketDisplayed, setTicketDisplay] = useState(null);

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Created',
      accessor: 'ticket.created_at',
      Cell: cell => {
        const { value } = cell;
        return formatDateDistance(value);
      },
      show: !isMobile,
    },
    {
      Header: 'User',
      accessor: 'user_name',
    },
    {
      Header: 'Priority',
      accessor: 'ticket.severity',
      Cell: cell => {
        const { value } = cell;
        return <PriorityCell value={value} isLite={isMobile} />;
      },
      width: 100,
    },
    {
      Header: 'Status',
      accessor: 'ticket.closed',
      Cell: cell => {
        const { value } = cell;
        return value ? 'Closed' : 'Open';
      },
      show: !isMobile,
    },
    {
      Header: '',
      accessor: 'ticket.seen',
      Cell: cell => {
        const { value } = cell;
        if (value) {
          return null;
        }
        return <IconBell />;
      },
      className: 'notification',
      width: 30,
    },
  ], [isMobile]);

  const handleRowClick = (val) => {
    setSelectedTicket(val);
    setTicketDisplay(true);
  };

  const handleTicketClose = () => {
    setSelectedTicket(null);
    setTicketDisplay(false);
  };

  return (
    <Styled.TicketsList>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onFetchData={() => {}}
        onRowClick={handleRowClick}
        rowClassName="TicketListRow"
      />
      {isTicketDisplayed && (
        <TicketDetails
          isOpen={isTicketDisplayed}
          onClose={handleTicketClose}
          ticket={selectedTicket}
          refreshUsers={refreshUsers}
        />
      )}
    </Styled.TicketsList>
  );
};

TicketsList.propTypes = {
  data: PropTypes.array,
  refreshUsers: PropTypes.func.isRequired,
};

TicketsList.defaultProps = {
  data: [],
};

export default TicketsList;
