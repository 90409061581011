import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  SidePanel,
  Selector,
} from 'shared/components';
import { adminRoleOptions, appName } from 'shared/constants';
import {
  IconClose,
  IconUserAdd,
  IconPasswordHide,
  IconPasswordShow,
} from 'shared/icons';
import api from 'shared/api';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .required(),
  email: yup
    .string()
    .email()
    .required(),
});

const InviteAdminForm = ({
  isOpen,
  closeCb,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [displayPassword, setPasswordDisplay] = useState(false);
  const [roleNames, setRoleNames] = useState([adminRoleOptions[0]]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: '',
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const formData = {
      user_name: data.email,
      password: data.password,
      role_name: get(roleNames, '[0].value'),
      app_name: appName,
    };

    api.post('/api/user-management/register', formData)
      .then(() => {
        setLoading(false);
        toast.success(`User ${data.email} registered`);
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={closeCb}>
      <Styled.InviteAdminForm>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconUserAdd />
            <Styled.HeaderTitleText>Add admin</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={closeCb}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.DetailsRow>
            <Styled.InputContainer>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="email-input"
                    label="Email"
                    placeholder="Enter email"
                    type="email"
                    error={errors.email?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="password"
                  label="Password"
                  placeholder="Create a password"
                  error={errors.password?.message}
                  type={displayPassword ? 'text' : 'password'}
                  button={displayPassword ? <IconPasswordHide /> : <IconPasswordShow />}
                  onBtnClick={() => setPasswordDisplay(!displayPassword)}
                  {...field}
                />
              )}
            />
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Selector
              id="role_name-input"
              name="role_name"
              label="Role"
              placeholder="Select role name"
              options={adminRoleOptions}
              onChange={(val) => setRoleNames(val)}
              values={roleNames}
              labelField="label"
              valueField="value"
            />
          </Styled.DetailsRow>
          <Button
            size="large"
            type="submit"
            isLoading={isLoading}
            loaderColor="#fff"
          >
            Submit
          </Button>
        </Styled.Form>
      </Styled.InviteAdminForm>
    </SidePanel>
  );
};

InviteAdminForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default InviteAdminForm;
