import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import api from 'shared/api';
import {
  Button,
  Page,
  PageLoader,
  Section,
} from 'shared/components';
import {
  OrganizationFiles,
  OrganizationForm,
  OrganizationLogoUpload,
  OrganizationOfferings,
  OrganizationOverview,
  OrganizationTeam,
} from '../components';
import * as Styled from './styles';

// status: `pending`, `submitted`, `processing`, `validated`, `failed`, `published`

const OrganizationPage = () => {
  const params = useParams();
  const { organizationID } = params;

  const [isLoading, setLoading] = useState(true);
  const [organizationData, setOrganizationData] = useState(null);
  const [isFormDisplayed, setFormDisplay] = useState(false);
  // organization offerings
  const [offerings, setOfferings] = useState([]);
  const [offeringsLoading, setOfferingsLoading] = useState(true);
  // organization files
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(true);

  const changeOrganizationStatus = useCallback((newStatus) => {
    const data = { status: newStatus };
    api.patch(`/api/user-management/business-entities/${organizationID}`, data)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        toast.success(`Organization ${newStatus} succesfully`);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [organizationID]);

  const getOrganizationOfferings = useCallback((ID) => {
    api.get(`/api/investing/business-entities/${ID}/offerings`)
      .then((res) => {
        setOfferings(get(res, 'data'));
        setOfferingsLoading(false);
      })
      .catch(() => {
        setOfferingsLoading(false);
      });
  }, []);

  const getOrganizationFiles = useCallback((ID) => {
    api.get(`/api/user-management/business-entities/${ID}/files`)
      .then((res) => {
        setFiles(get(res, 'data'));
        setFilesLoading(false);
      })
      .catch(() => {
        setFilesLoading(false);
      });
  }, []);

  const getOrganization = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}`)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        getOrganizationOfferings(get(res, 'data.id'));
        getOrganizationFiles(get(res, 'data.id'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [organizationID]);

  // const handleFeatured = useCallback(() => {
  //   api.post(`/api/user-management/business-entities/${organizationID}/tags`, { tag_title: 'featured' })
  //     .then(() => {
  //       toast.success('Organization set as featured');
  //       setLoading(false);
  //     })
  //     .catch(() => {
  //       toast.error('Error occured');
  //       setLoading(false);
  //     });
  // }, [organizationID]);

  useEffect(() => {
    getOrganization();
  }, [getOrganization]);

  if (isLoading) {
    return <Page showBack type="yoc_user" title="Your own circle"><PageLoader /></Page>;
  }

  const { status } = organizationData;

  return (
    <Page type="organizations" title="Organization">
      <Styled.OrganizationPage>
        <Styled.Main>
          <Styled.Page>
            <Styled.Aside>
              <Styled.LogoContainer>
                <OrganizationLogoUpload organization={organizationData} />
              </Styled.LogoContainer>
              <Styled.Title>{get(organizationData, 'name')}</Styled.Title>
              <Button
                variant="outlined"
                size="small"
                type="submit"
                handleClick={() => setFormDisplay(true)}
              >
                Edit
              </Button>
              {status === 'submitted' && (
                <>
                  <Button size="small" handleClick={() => changeOrganizationStatus('validated')}>
                    Accept KYB
                  </Button>
                  <Button variant="text" size="small" handleClick={() => changeOrganizationStatus('failed')}>
                    Reject KYB
                  </Button>
                </>
              )}
              {status === 'validated' && (
                <>
                  <Button size="small" handleClick={() => changeOrganizationStatus('published')}>
                    Publish
                  </Button>
                </>
              )}
              {/* {status === 'published' && (
                <>
                  <Button size="small" handleClick={handleFeatured}>
                    Set as featured
                  </Button>
                </>
              )} */}
            </Styled.Aside>
            <Styled.Data>
              <Section
                title="Overview"
                content={<OrganizationOverview organization={organizationData} />}
              />
              <OrganizationTeam
                organization={organizationData}
                isAuth
              />
              <OrganizationOfferings
                offerings={offerings}
                loading={offeringsLoading}
                isAuth
                status={status}
              />
              <OrganizationFiles
                files={files}
                loading={filesLoading}
              />
            </Styled.Data>
          </Styled.Page>
        </Styled.Main>
        {isFormDisplayed && (
          <OrganizationForm
            isOpen={isFormDisplayed}
            closeCb={() => {
              getOrganization();
              setFormDisplay(false);
            }}
            organization={organizationData}
          />
        )}
      </Styled.OrganizationPage>
    </Page>
  );
};

export default OrganizationPage;
