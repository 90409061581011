import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import api from 'shared/api';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import { defaultDateFormat } from 'shared/constants';
// import { useWindowDimensions } from 'shared/HOCs';
import {
  PageLoader,
  Table,
} from 'shared/components';
import * as Styled from './styles';

const OfferingDealRooms = ({
  offering,
}) => {
  const history = useHistory();
  // const { isMobile } = useWindowDimensions();
  const offeringID = get(offering, 'id');

  const [isLoading, setLoading] = useState(true);
  const [dealRooms, setDealRomms] = useState([]);

  const getDealRooms = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/deal-rooms`)
      .then((res) => {
        setDealRomms(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getDealRooms();
  }, [getDealRooms]);

  const tableData = useMemo(() => dealRooms, [dealRooms]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: cell => capitalizeFirstLetter(cell.value, 'N/A'),
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateFormat),
      // show: !isMobile,
    },
  ], [dealRooms]);

  const handleRowClick = row => {
    history.push(`/offerings/${offeringID}/dataroom/${get(row, 'id')}`);
  };

  if (isLoading) {
    return <Styled.OfferingDealRooms><PageLoader /></Styled.OfferingDealRooms>;
  }

  return (
    <Styled.OfferingDealRooms>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="OfferingDealroomsListRow"
      />
    </Styled.OfferingDealRooms>
  );
};

OfferingDealRooms.propTypes = {
  offering: PropTypes.object.isRequired,
};

export default OfferingDealRooms;
