import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import ReactModal from 'react-modal';
import {
  Button,
  Checkbox,
  RadioBtn,
  DatePicker,
  Selector,
  TextArea,
  TextInput,
} from 'shared/components';
import { IconClose } from 'shared/icons';
import api from 'shared/api';
import {
  parseObject,
  mapCurrenciesToSelector,
  mapOrganizationsToSelector,
} from 'shared/helpers';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .required(),
  target: yup
    .number()
    .min(1)
    .required(),
});

const OfferingForm = ({
  isOpen,
  closeCb,
  offering,
  organizationID,
}) => {
  const history = useHistory();

  const currencies = useSelector(state => get(state, 'investing.currencies'));
  const currenciesOptions = mapCurrenciesToSelector(currencies);
  const usd = currenciesOptions.find(c => c.value === 'USD');
  const offeringCurrency = currenciesOptions.find(c => get(c, 'data.id') === get(offering, 'currency_id'));
  const initialCurrency = offeringCurrency || usd;
  const isOverfundingAllowed = get(offering, 'allow_max_overfunding');
  const maxOverfundingValue = get(offering, 'max_overfunding');
  const isUnlimitedOverfunding = isOverfundingAllowed && !maxOverfundingValue;

  const [isLoading, setLoading] = useState(false);
  const [organizationsSearch, setOrganizationsSearch] = useState('');
  const [organizationsLoading, setOrganizationsLoading] = useState(true);
  const [organizationsOptions, setOrganizationsOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [currency, setCurrency] = useState([initialCurrency]);
  const [allowOverfunding, setAllowOverfunding] = useState(isOverfundingAllowed);
  const [overfundingType, setOverfundingType] = useState(isUnlimitedOverfunding ? 'unlimited' : 'value');

  const offeringSummary = parseObject(get(offering, 'bio'), '');
  // const symbol = get(currency, '[0].data.symbol') || '';

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: get(offering, 'name') || '',
      description: get(offering, 'description') || '',
      opened_at: get(offering, 'opened_at') || null,
      closed_at: get(offering, 'closed_at') || null,
      target: Number(get(offering, 'target')) || '',
      min_investment: Number(get(offering, 'min_investment')) || '',
      max_investment: Number(get(offering, 'max_investment')) || '',
      max_overfunding: Number(get(offering, 'max_overfunding')) || '',
      prev_investments: Number(get(offering, 'prev_investments')) || '',
      pre_money_valuation: Number(get(offering, 'pre_money_valuation')) || '',
      issued_shares: Number(get(offering, 'issued_shares')) || '',
      outstanding_shares: Number(get(offering, 'outstanding_shares')) || '',
      off_platform_investments: Number(get(offering, 'off_platform_investments')) || '',
      purpose: get(offeringSummary, 'purpose') || '',
      market: get(offeringSummary, 'market') || '',
      business_model: get(offeringSummary, 'business_model') || '',
      offering: get(offeringSummary, 'offering') || '',
      dirtyForm: '',
    },
  });

  useEffect(() => {
    if (organizationID) {
      api.get(`/api/user-management/business-entities/${organizationID}`)
        .then((res) => {
          const org = get(res, 'data');
          const orgList = mapOrganizationsToSelector([org]);
          setOrganizationsOptions(orgList);
          setSelectedOrganization([orgList[0]]);
          setOrganizationsLoading(false);
        })
        .catch(() => {
          setOrganizationsLoading(false);
        });
    } else {
      api.get('/api/user-management/business-entities?page=1&per_page=20')
        .then((res) => {
          const list = get(res, 'data.records');
          const mappedList = mapOrganizationsToSelector(list);
          const initialOrg = mappedList.length ? [get(mappedList, '[0]')] : [];
          setOrganizationsOptions(mappedList);
          setSelectedOrganization(initialOrg);
          setOrganizationsLoading(false);
        })
        .catch(() => {
          setOrganizationsLoading(false);
        });
    }
  }, [organizationID]);

  const createOffering = (data) => {
    const expandedData = {
      ...data,
      shares_type: 'preferred',
      fin_instrument_type: 'equity_issuing',
      fin_instrument_subtype: 'instrument_issuance',
    };
    api.post('/api/investing/offerings', expandedData)
      .then((res) => {
        const resData = get(res, 'data');
        toast.success('Offering created succesfully');
        setLoading(false);
        history.push(`/offerings/${get(resData, 'id')}`);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const updateOffering = (data) => {
    const offeringID = get(offering, 'id');
    api.patch(`/api/investing/offerings/${offeringID}`, data)
      .then(() => {
        toast.success('Offering updated succesfully');
        setLoading(false);
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const getBio = (data) => {
    const json = {
      purpose: get(data, 'purpose') || '',
      market: get(data, 'market') || '',
      business_model: get(data, 'business_model') || '',
      offering: get(data, 'offering') || '',
    };
    return JSON.stringify(json);
  };

  const isOrganizationPublished = () => {
    const status = get(selectedOrganization, '[0].data.status');
    return status === 'published';
  };

  const getMaxOverfundingValue = (data) => {
    if (!allowOverfunding) {
      return null;
    }
    if (overfundingType === 'unlimited') {
      return null;
    }
    return Number(get(data, 'max_overfunding')) || null;
  };

  const onSubmit = (data) => {
    if (!isDirty || isLoading) {
      return false;
    }
    if (!selectedOrganization.length) {
      toast.error('No organization has been selected.');
      return false;
    }

    const isOrgPublished = isOrganizationPublished();
    if (!isOrgPublished) {
      toast.error('Selected organization is not published.');
      return false;
    }

    setLoading(true);
    // required: name, shares_type , target , fin_instrument_type i fin_instrument_subtype
    // stage can be: draft, submitted, due_diligence, rejected, approved, restricted, published, closing, settled, canceled
    const postData = {
      stage: get(offering, 'stage') || 'draft',
      name: get(data, 'name'),
      description: get(data, 'description'),
      bio: getBio(data),
      business_entity_id: get(selectedOrganization, '[0].data.id'),
      currency_code: get(currency, '[0].value'),
      currency_id: get(currency, '[0].data.id'),
      opened_at: get(data, 'opened_at') || undefined,
      closed_at: get(data, 'closed_at') || undefined,
      target: Number(get(data, 'target')),
      min_investment: Number(get(data, 'min_investment')) || null,
      max_investment: Number(get(data, 'max_investment')) || null,
      prev_investments: Number(get(data, 'prev_investments')) || null,
      pre_money_valuation: Number(get(data, 'pre_money_valuation')) || null,
      issued_shares: Number(get(data, 'issued_shares')) || null,
      outstanding_shares: Number(get(data, 'outstanding_shares')) || null,
      off_platform_investments: Number(get(data, 'off_platform_investments')) || null,
      allow_max_overfunding: allowOverfunding,
      max_overfunding: getMaxOverfundingValue(data),
    };

    if (!offering) {
      createOffering(postData);
    } else {
      updateOffering(postData);
    }
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="OfferingFormModal"
    >
      <Styled.OfferingForm>
        <Styled.Main>
          <Styled.Form>
            <Styled.Data>
              <Styled.InputContainer>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="name"
                      label="Offering title *"
                      placeholder='Name of your funding round (e.g. "Series A")'
                      wide
                      disabled={isLoading}
                      error={errors.name?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="description"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="description"
                      label="Description *"
                      placeholder="Shortly describe your offering to capture investors' attention"
                      wide
                      disabled={isLoading}
                      error={errors.description?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.SectionLabel>
                Financial instrument
              </Styled.SectionLabel>
              <Styled.DualInputRow>
                <Controller
                  name="opened_at"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <DatePicker
                      id="opened_at"
                      name="opened_at"
                      label="Start date"
                      placeholder="aaaa/mm/dd"
                      wide
                      disabled={isLoading}
                      handleChange={(date) => field.onChange(date)}
                      value={field.value ? new Date(field.value) : null}
                      error={errors.opened_at?.message}
                    />
                  )}
                />
                <Controller
                  name="closed_at"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <DatePicker
                      id="closed_at"
                      name="closed_at"
                      label="End date"
                      placeholder="aaaa/mm/dd"
                      wide
                      disabled={isLoading}
                      handleChange={(date) => field.onChange(date)}
                      value={field.value ? new Date(field.value) : null}
                      error={errors.closed_at?.message}
                    />
                  )}
                />
              </Styled.DualInputRow>
              <Styled.DualInputRow>
                <Controller
                  name="target"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="target"
                      label="Funding target *"
                      placeholder="Your funding target"
                      type="number"
                      // prefix={symbol}
                      wide
                      disabled={isLoading}
                      error={errors.target?.message}
                      showError={false}
                      {...field}
                    />
                  )}
                />
                <Selector
                  id="currency-input"
                  name="selected-currency"
                  label="Currency"
                  options={currenciesOptions}
                  onChange={(val) => {
                    setValue('dirtyForm', 'true', { shouldDirty: true });
                    setCurrency(val);
                  }}
                  values={currency}
                  labelField="label"
                  valueField="value"
                  searchable
                />
              </Styled.DualInputRow>
              <Styled.DualInputRow>
                <Controller
                  name="min_investment"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="min_investment"
                      label="Min investment"
                      placeholder="Minimum amount for a single investor"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.min_investment?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="max_investment"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="max_investment"
                      label="Max investment"
                      placeholder="Maximum amount for a single investor"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.max_investment?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.DualInputRow>
              <Styled.DualInputRow>
                <Controller
                  name="prev_investments"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="prev_investments"
                      label="Previous rounds"
                      placeholder="External commitments before new round"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.prev_investments?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="off_platform_investments"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="off_platform_investments"
                      label="Off plaftorm investments"
                      placeholder="Off-platform secured resources for this round"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.off_platform_investments?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.DualInputRow>
              <Styled.DualInputRow>
                <Controller
                  name="issued_shares"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="issued_shares"
                      label="Founders shares"
                      placeholder="Shares owned by founders and shareholders"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.issued_shares?.message}
                      {...field}
                    />
                  )}
                />
                <Controller
                  name="outstanding_shares"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="outstanding_shares"
                      label="Total outstanding shares"
                      placeholder="Total number of shares currently issued"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.outstanding_shares?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.DualInputRow>
              <Styled.DualInputRow>
                <Controller
                  name="pre_money_valuation"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextInput
                      id="pre_money_valuation"
                      label="Pre-money Valuation"
                      placeholder="Equity value before this round"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.pre_money_valuation?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.DualInputRow>
              <Styled.OverfundingContainer>
                <Checkbox
                  id="allow_overfunding"
                  onChange={(e) => {
                    setValue('dirtyForm', 'true', { shouldDirty: true });
                    setAllowOverfunding(e.target.checked);
                    setOverfundingType('unlimited');
                  }}
                  label="Allow overfunding"
                  checked={allowOverfunding}
                />
                {allowOverfunding && (
                  <Styled.RadioBtnContainer>
                    <RadioBtn
                      id="overfunding_unlimited"
                      name="overfunding_type"
                      value="unlimited"
                      label="Unlimited overfunding"
                      onChange={() => setOverfundingType('unlimited')}
                      checked={overfundingType === 'unlimited'}
                    />
                    <RadioBtn
                      id="overfunding_value"
                      name="overfunding_type"
                      value="value"
                      label="Max overfunding"
                      onChange={() => setOverfundingType('value')}
                      checked={overfundingType === 'value'}
                    />
                  </Styled.RadioBtnContainer>
                )}
              </Styled.OverfundingContainer>
              {overfundingType === 'value' && allowOverfunding && (
                <Styled.DualInputRow>
                  <Controller
                    name="max_overfunding"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextInput
                        id="max_overfunding"
                        label="Set max overfunding amount"
                        placeholder="Amount exceeding the set target (optional)"
                        type="number"
                        wide
                        disabled={isLoading}
                        error={errors.max_overfunding?.message}
                        {...field}
                      />
                    )}
                  />
                </Styled.DualInputRow>
              )}
              <Styled.SectionLabel>
                Summary
              </Styled.SectionLabel>
              <Styled.InputContainer>
                <Controller
                  name="purpose"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextArea
                      id="purpose"
                      label="Company's purpose"
                      placeholder="Tell us more about your company purpose. Are there any problems you are trying to solve? What is the solution?"
                      type="number"
                      wide
                      disabled={isLoading}
                      error={errors.purpose?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="market"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextArea
                      id="market"
                      label="Market analysis"
                      placeholder="Describe the market your company is acting in. Are there any significant competitors? What are the market dynamics?"
                      wide
                      disabled={isLoading}
                      error={errors.market?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="business_model"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextArea
                      id="business_model"
                      label="Business model"
                      placeholder="What is your business model? Tell us about your product and revenue model."
                      wide
                      disabled={isLoading}
                      error={errors.business_model?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
              <Styled.InputContainer>
                <Controller
                  name="offering"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextArea
                      id="offering"
                      label="Offering"
                      placeholder="Tell us about the use of proceeds and what you have achieved till now with previously raised funds."
                      wide
                      disabled={isLoading}
                      error={errors.offering?.message}
                      {...field}
                    />
                  )}
                />
              </Styled.InputContainer>
            </Styled.Data>
            <Styled.Aside>
              <Styled.InputContainer>
                <Selector
                  id="organization-input"
                  name="selected-organization"
                  label="Organization"
                  options={organizationsOptions}
                  onChange={(val) => {
                    setValue('dirtyForm', 'true', { shouldDirty: true });
                    setSelectedOrganization(val);
                  }}
                  values={selectedOrganization}
                  labelField="label"
                  valueField="value"
                  placeholder="Search..."
                  searchable
                  searchFn={({ state, methods }) => {
                    if (state.search !== organizationsSearch) {
                      setOrganizationsSearch(state.search);
                      api.get(`/api/user-management/business-entities?page=1&per_page=10000&name=${methods.safeString(state.search)}`)
                        .then((res) => {
                          const list = get(res, 'data.records');
                          setOrganizationsOptions(mapOrganizationsToSelector(list));
                        })
                        .catch(() => []);
                    }
                  }}
                  disabled={!!offering || !!organizationID || organizationsLoading}
                />
              </Styled.InputContainer>
              <Button
                size="small"
                type="submit"
                handleClick={() => handleSubmit(onSubmit)()}
              >
                Save
              </Button>
            </Styled.Aside>
          </Styled.Form>
        </Styled.Main>
      </Styled.OfferingForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

OfferingForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  offering: PropTypes.object,
  organizationID: PropTypes.number,
};

OfferingForm.defaultProps = {
  offering: null,
  organizationID: null,
};

export default OfferingForm;
