import {
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
// import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import api from 'shared/api';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import { useWindowDimensions } from 'shared/HOCs';
import {
  Button,
  Loader,
  PageLoader,
  Table,
} from 'shared/components';
import { IconDownload, IconDelete } from 'shared/icons';
import DealRoomFilesUpload from '../DealRoomFilesUpload';
import * as Styled from './styles';

const DealRoomFiles = ({
  dealRoom,
  files,
  members,
  loading,
  refetchFiles,
}) => {
  const { isMobile } = useWindowDimensions();
  // const user = useSelector(state => get(state, 'account.user'));
  // const dealRoomID = get(dealRoom, 'id');

  const [fileDeleting, setFileDeleting] = useState(null);
  const [fileDownloading, setFileDownloading] = useState(null);
  const [isAddFilesDisplayed, setAddFilesDisplayed] = useState(false);

  const handleDocDelete = (file) => {
    const fileID = get(file, 'id');
    setFileDeleting(fileID);
    api.delete(`/api/investing/deal-rooms/files/${fileID}`)
      .then(() => {
        refetchFiles();
        setFileDeleting(null);
        toast.success('Dealroom document deleted');
      })
      .catch(() => {
        setFileDeleting(null);
        toast.error('Error occured');
      });
  };

  const handleDocDownload = (file) => {
    if (fileDownloading) {
      return false;
    }
    const fileUrl = get(file, 'parts[0]');
    const fileID = get(file, 'id');
    const fileExtension = get(file, 'file_extension');
    const fileName = get(file, 'name');
    setFileDownloading(fileID);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': `application/${fileExtension}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          // eslint-disable-next-line no-undef
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const fallbackName = `business_entity_document-${fileID}`;
        link.setAttribute(
          'download',
          `${fileName || fallbackName}`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setFileDownloading(null);
      })
      .catch(() => {
        setFileDownloading(null);
        toast.error('Error occured');
      });
    return true;
  };

  const tableData = useMemo(() => files, [files]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: cell => capitalizeFirstLetter(cell.value, 'N/A'),
    },
    {
      Header: 'File extension',
      accessor: 'file_extension',
      Cell: cell => `.${get(cell, 'value') || 'N/A'}`,
      show: !isMobile,
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: cell => formatDate(get(cell, 'value'), defaultDateTimeFormat),
      show: !isMobile,
    },
    {
      Header: 'Download',
      headerClassName: 'centered',
      id: 'download',
      Cell: (cell) => {
        const isDownloading = fileDownloading === get(cell, 'cell.row.original.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={() => handleDocDownload(get(cell, 'row.original'))}
            >
              {isDownloading ? <Loader /> : <IconDownload />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 110,
      maxWidth: 110,
    },
    {
      Header: 'Delete',
      headerClassName: 'centered',
      id: 'delete',
      Cell: (cell) => {
        const isDeleting = fileDeleting === get(cell, 'cell.row.original.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={() => handleDocDelete(get(cell, 'row.original'))}
            >
              {isDeleting ? <Loader /> : <IconDelete color="#CC3429" />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 80,
      maxWidth: 80,
    },
  ], [files, fileDownloading, fileDeleting]);

  if (loading) {
    return <Styled.DealRoomFiles><PageLoader /></Styled.DealRoomFiles>;
  }

  return (
    <Styled.DealRoomFiles>
      <Button
        size="small"
        type="submit"
        handleClick={() => setAddFilesDisplayed(true)}
      >
        Add files
      </Button>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable={false}
        rowClassName="UsersListRow"
      />
      {isAddFilesDisplayed && (
        <DealRoomFilesUpload
          isOpen={isAddFilesDisplayed}
          closeCb={() => setAddFilesDisplayed(false)}
          dealRoom={dealRoom}
          refetchFiles={refetchFiles}
          members={members}
        />
      )}
    </Styled.DealRoomFiles>
  );
};

DealRoomFiles.propTypes = {
  dealRoom: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  refetchFiles: PropTypes.func.isRequired,
};

export default DealRoomFiles;
