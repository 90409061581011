import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import {
  Button,
  UserInfoCell,
  Loader,
  Notice,
} from 'shared/components';
import { OfferingCard } from 'Offerings/components';
import api from 'shared/api';
import * as Styled from './styles';

const OfferingMemberNotification = ({
  closeCb,
  offeringMember,
  // eslint-disable-next-line no-unused-vars
  notification,
}) => {
  const history = useHistory();
  const offeringID = get(offeringMember, 'OfferingMember.member.offering_id') || '';
  const memberName = get(offeringMember, 'OfferingMember.profile.full_name') || '';
  const memberID = get(offeringMember, 'OfferingMember.member.id') || '';
  const userProfile = get(offeringMember, 'OfferingMember.profile') || '';
  const userID = get(offeringMember, 'OfferingMember.profile.user_id') || '';
  const maxInvestment = get(offeringMember, 'OfferingMember.member.max_investment') || '';
  const minInvestment = get(offeringMember, 'OfferingMember.member.min_investment') || '';
  const status = get(offeringMember, 'OfferingMember.member.status') || 'pending';

  const [isOfferingLoading, setOfferingLoading] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [offeringData, setOfferingData] = useState(null);

  const acceptInterest = useCallback(() => {
    setLoading(true);
    api.patch(`/api/investing/offerings/members/${memberID}`, { status: 'approved' })
      .then(() => {
        history.push(`/offerings/${offeringID}/dataroom`);
        toast.success('Offering member approved succesfully');
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [memberID]);

  const declineInterest = useCallback(() => {
    setLoading(true);
    api.patch(`/api/investing/offerings/members/${memberID}`, { status: 'declined' })
      .then(() => {
        toast.success('Offering member request declined');
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [memberID]);

  const getOffering = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data'));
        setOfferingLoading(false);
      })
      .catch(() => {
        setOfferingLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOffering();
  }, [getOffering]);

  const getNotificationText = () => {
    switch (status) {
      case 'pending':
        return `${memberName} has submitted the interest request to the offering. Min investment ${Number(minInvestment)}, max investment ${Number(maxInvestment)}`;
      default:
        return `Offering interest request has been ${status}`;
    }
  };

  const showActions = status === 'pending';
  const isApproved = status === 'approved';

  if (isOfferingLoading) {
    return (
      <Styled.OfferingMemberNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.OfferingMemberNotification>
    );
  }

  return (
    <Styled.OfferingMemberNotification>
      <Styled.Text>{getNotificationText()}</Styled.Text>
      <Styled.Member>
        <span>User profile:</span>
        {/* THIS needs to go to /yoc on client and /users on admin app */}
        <Link to={`/users/${userID}`}>
          <UserInfoCell
            profileImg={get(userProfile, 'profile_picture')}
            name={get(userProfile, 'full_name')}
            metaFirst={get(userProfile, 'user_name')}
          />
        </Link>
      </Styled.Member>
      <Styled.Offering>
        <span>Offering:</span>
        <OfferingCard
          handleCardClick={() => history.push(`offerings/${offeringID}`)}
          offering={offeringData}
        />
      </Styled.Offering>
      {showActions && (
        <Styled.ButtonsContainer>
          <Button disabled={isLoading} handleClick={acceptInterest}>Accept</Button>
          <Button disabled={isLoading} handleClick={declineInterest} variant="warning">Decline</Button>
        </Styled.ButtonsContainer>
      )}
      {isApproved && (
        <Styled.Expired>
          <Notice
            title="Offering interest request has been approved."
            type="success"
          />
        </Styled.Expired>
      )}
    </Styled.OfferingMemberNotification>
  );
};

OfferingMemberNotification.propTypes = {
  offeringMember: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default OfferingMemberNotification;
