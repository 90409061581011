import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Page,
  PageLoader,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import { IconOffering } from 'shared/icons';
import {
  OfferingFiles,
  OfferingMembers,
  OfferingOwners,
  OfferingDealRooms,
  OfferingInvestments,
} from '../components';
import * as Styled from './styles';

const OfferingDataroom = ({
  // eslint-disable-next-line no-unused-vars
  isAuth,
}) => {
  const params = useParams();
  // eslint-disable-next-line no-unused-vars
  const history = useHistory();
  const { offeringID } = params;

  const [isLoading, setLoading] = useState(true);
  const [offeringData, setOfferingData] = useState(null);

  const getOffering = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}`)
      .then((res) => {
        setOfferingData(get(res, 'data.offering'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getOffering();
  }, [getOffering]);

  if (isLoading) {
    return <Page showBack type="offerings" title="Offering dataroom"><PageLoader /></Page>;
  }

  const offeringName = get(offeringData, 'name');

  return (
    <Page type="offerings" title="Offering dataroom">
      <Styled.OfferingDataroom>
        <Styled.OfferingTabs>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconOffering color="#20201E" /></Styled.TitleIcon>
              <Styled.TitleText>
                <Link to={`/offerings/${offeringID}`}>{`${offeringName}`}</Link>
                <Styled.Heading>dataroom</Styled.Heading>
              </Styled.TitleText>
            </Styled.ListTitle>
          </Styled.ListHeader>
          <Tabs>
            <TabsHeader>
              <Tab>Members</Tab>
              <Tab>Owners</Tab>
              <Tab>Files</Tab>
              <Tab>Dealrooms</Tab>
              <Tab>Investments</Tab>
            </TabsHeader>
            <TabContent>
              <OfferingMembers offering={offeringData} isAuth />
            </TabContent>
            <TabContent>
              <OfferingOwners offering={offeringData} isAuth />
            </TabContent>
            <TabContent>
              <OfferingFiles offering={offeringData} isAuth />
            </TabContent>
            <TabContent>
              <OfferingDealRooms offering={offeringData} isAuth />
            </TabContent>
            <TabContent>
              <OfferingInvestments offering={offeringData} />
            </TabContent>
          </Tabs>
        </Styled.OfferingTabs>
      </Styled.OfferingDataroom>
    </Page>
  );
};

OfferingDataroom.propTypes = {
  isAuth: PropTypes.bool,
};

OfferingDataroom.defaultProps = {
  isAuth: false,
};

export default OfferingDataroom;
