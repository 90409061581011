import * as actionTypes from './actionTypes';

const clearNavigationHistoryValues = () => ({
  type: actionTypes.clearNavigationHistory,
});

export const clearNavigationHistory = () => (dispatch) => {
  dispatch(clearNavigationHistoryValues());
};

const addNavigationHistoryValue = value => ({
  type: actionTypes.addToNavigationHistory,
  value,
});

export const appendToNavigationHistory = (value) => (dispatch) => {
  dispatch(addNavigationHistoryValue(value));
};
