/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const Onboarding = styled.div`
  padding: 22px 24px 12px 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  /* max-width: 920px; */
  background-color: ${props => props.theme.colors.shades.neutral.white};
`;

export const Indicators = styled.div`
  display: flex;
  align-items: center;
`;

export const OnboardingActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`;

export const OnboardingMain = styled.div`

`;

export const StepContainer = styled.div`
  display: none;
  height: 100%;
  max-width: 300px;
  padding-top: 80px;

  ${props => {
    if (props.isActive) {
      return css`
        display: flex;
        flex-direction: column;
      `;
    }
  }}
`;

export const StepIcon = styled.div`
  margin-bottom: 30px;
`;

export const StepTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.neutral.gray90};
  margin-bottom: 8px;
`;

export const StepText = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.neutral.gray90};
`;
