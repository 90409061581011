import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import ReactModal from 'react-modal';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, TextInput } from 'shared/components';
import api from 'shared/api';
import { IconBank, IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const validationSchema = yup.object().shape({
  account_name: yup
    .string()
    .required(),
  account_number: yup
    .string()
    .required(),
});

const BankAccountForm = ({
  isOpen,
  closeCb,
  refreshUser,
  user,
  bankAccount,
}) => {
  const userID = get(user, 'user_id');
  const [isLoading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      account_name: get(bankAccount, 'account_name') || '',
      account_number: get(bankAccount, 'account_number') || '',
    },
  });

  const editBankAccount = (data) => {
    const accID = get(bankAccount, 'id');
    api.patch(`/api/user-management/bank-accounts/${accID}`, data)
      .then(() => {
        toast.success('Bank account edited');
        refreshUser();
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const createBankAccount = (data) => {
    api.post(`/api/user-management/users/${userID}/bank-accounts`, data)
      .then(() => {
        toast.success('Bank account added');
        refreshUser();
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  };

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (!isDirty || isLoading) {
      return false;
    }

    setLoading(true);
    const formData = {
      account_name: data.account_name,
      account_number: data.account_number,
    };

    if (bankAccount) {
      editBankAccount(formData);
    } else {
      createBankAccount(formData);
    }
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="BankAccountFormModal"
    >
      <Styled.BankAccountForm>
        <Styled.Header>
          <IconBank />
          <Styled.HeaderTitle>Bank account</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.InputContainer>
            <Controller
              name="account_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="account_name"
                  label="Account name"
                  placeholder="e.g. Main account"
                  wide
                  disabled={isLoading}
                  error={errors.account_name?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputContainer>
          <Styled.InputContainer>
            <Controller
              name="account_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="account_number"
                  label="Account number"
                  placeholder="e.g. 123123421342342"
                  wide
                  disabled={isLoading}
                  error={errors.account_number?.message}
                  {...field}
                />
              )}
            />
          </Styled.InputContainer>
          <Styled.ButtonContainer>
            <Button type="submit">
              Save
            </Button>
          </Styled.ButtonContainer>
        </Styled.Form>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.BankAccountForm>
    </ReactModal>
  );
};

BankAccountForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  bankAccount: PropTypes.object,
  refreshUser: PropTypes.func.isRequired,
};

BankAccountForm.defaultProps = {
  bankAccount: null,
};

export default BankAccountForm;
