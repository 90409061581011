import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import api from 'shared/api';
import { Button, Loader } from 'shared/components';
import {
  IconYOC,
  IconClose,
  IconArrowBack,
  IconNoProfilePicture,
} from 'shared/icons';
import { capitalizeFirstLetter } from 'shared/helpers';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '9999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '460px',
  },
};

const UserCirclesModal = ({
  isOpen,
  closeCb,
  data,
  dataCount,
  userID,
  userClickCb,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedCircle, setSelectedCircle] = useState(null);
  const [circleUsers, setCircleUsers] = useState([]);

  const handleClose = () => {
    closeCb();
  };

  const getCircleUsers = (circle) => {
    api.get(`/api/yoc/users/${userID}/connections?page=1&per_page=1000&mode=inviter&topo_type_name=${circle.name}`)
      .then((res) => {
        setLoading(false);
        const yocData = get(res, 'data');
        setCircleUsers(get(yocData, 'records'));
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (isLoading) {
    return (
      <ReactModal
        isOpen={isOpen}
        style={styles}
        onRequestClose={() => { }}
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={150}
        className="UserCirclesModalModal"
      >
        <Styled.UserCirclesModal>
          <Styled.Header>
            <IconYOC color="#20201E" />
            <Styled.HeaderTitle>{`${capitalizeFirstLetter(selectedCircle.name)} circle (${selectedCircle.count})`}</Styled.HeaderTitle>
          </Styled.Header>
          <Styled.Main>
            <Loader color="#616E7F" />
          </Styled.Main>
          <Styled.CloseBtn>
            <Button
              variant="text"
              size="small"
              onClick={handleClose}
            >
              <IconClose color="#A19E95" width={12} height={12} />
            </Button>
          </Styled.CloseBtn>
        </Styled.UserCirclesModal>
      </ReactModal>
    );
  }

  if (selectedCircle) {
    return (
      <ReactModal
        isOpen={isOpen}
        style={styles}
        onRequestClose={() => { }}
        shouldCloseOnOverlayClick={false}
        closeTimeoutMS={150}
        className="UserCirclesModalModal"
      >
        <Styled.UserCirclesModal>
          <Styled.Header>
            <Button
              variant="outlined"
              size="small"
              handleClick={() => {
                setSelectedCircle(null);
                setCircleUsers([]);
              }}
            >
              <IconArrowBack color="#20201E" />
            </Button>
            <Styled.HeaderTitle>{`${capitalizeFirstLetter(selectedCircle.name)} circle (${selectedCircle.count})`}</Styled.HeaderTitle>
          </Styled.Header>
          <Styled.Main>
            {circleUsers.map((u) => (
              <Styled.TopologyItem
                type="button"
                onClick={() => {
                  userClickCb(u);
                  closeCb();
                }}
                key={u.user_id}
              >
                <Styled.UserItem>
                  <IconNoProfilePicture />
                  {capitalizeFirstLetter(u.full_name)}
                </Styled.UserItem>
                <Styled.UserStatus>{capitalizeFirstLetter(u.status)}</Styled.UserStatus>
              </Styled.TopologyItem>
            ))}
          </Styled.Main>
          <Styled.CloseBtn>
            <Button
              variant="text"
              size="small"
              onClick={handleClose}
            >
              <IconClose color="#A19E95" width={12} height={12} />
            </Button>
          </Styled.CloseBtn>
        </Styled.UserCirclesModal>
      </ReactModal>
    );
  }

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="UserCirclesModalModal"
    >
      <Styled.UserCirclesModal>
        <Styled.Header>
          <IconYOC color="#20201E" />
          <Styled.HeaderTitle>{`Users circles (${dataCount})`}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Main>
          {data.map((d) => (
            <Styled.TopologyItem
              type="button"
              onClick={() => {
                setLoading(true);
                setSelectedCircle(d);
                getCircleUsers(d);
              }}
              key={d.name}
            >
              <Styled.TopologyItemTitle>{capitalizeFirstLetter(d.name)}</Styled.TopologyItemTitle>
              <Styled.TopologyItemCount>{d.count}</Styled.TopologyItemCount>
            </Styled.TopologyItem>
          ))}
        </Styled.Main>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.UserCirclesModal>
    </ReactModal>
  );
};

UserCirclesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  data: PropTypes.array,
  dataCount: PropTypes.number,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userClickCb: PropTypes.func,
};

UserCirclesModal.defaultProps = {
  data: [],
  dataCount: 0,
  userClickCb: () => {},
};

export default UserCirclesModal;
