import { get } from 'lodash';
import moment from 'moment';
import { defaultDateFormat, adminRoleOptions, clientRoleOptions } from 'shared/constants';

export const formatDateDistance = (val, formatType = defaultDateFormat, fallback = '-') => {
  const today = moment().local();
  const date = moment.utc(val).local();
  const isDateValid = moment(val).isValid();

  if (!val || !isDateValid) {
    return fallback;
  }

  const daysfromToday = date.diff(today, 'days');
  if (daysfromToday > 6) {
    return moment(val).format(formatType);
  }

  return date.fromNow();
};

export const formatDate = (val, formatType = defaultDateFormat, fallback = '-') => {
  const isDateValid = moment(val).isValid();

  if (!val || !isDateValid) {
    return fallback;
  }
  return moment.utc(val).local().format(formatType);
};

export const checkKycStatus = (status) => {
  const value = status ? status.toLowerCase() : 'pending';
  switch (value) {
    case 'external':
      return ({
        isValidated: false,
        isExternal: true,
        status: value,
      });
    case 'pending':
      return ({
        isValidated: false,
        isExternal: false,
        status: value,
      });
    case 'failed':
      return ({
        isValidated: false,
        isExternal: false,
        status: value,
      });
    case 'validated':
      return ({
        isValidated: true,
        isExternal: false,
        status: value,
      });
    default:
      return ({
        isValidated: false,
        isExternal: false,
        status: '',
      });
  }
};

export const getUUID = (params) => {
  const value = params.split('?')[1].split('&')[0].split('=')[1];
  return value;
};

export const getChapterState = (userAnswers, chapter) => {
  const answeredInChapter = [];
  const answerIDs = userAnswers.map(a => a.answer_id);
  const chapterAnswerArrays = chapter.questions.map(q => q.answers);

  const isFinished = chapterAnswerArrays.every(cAnswers => {
    const doneAnswer = cAnswers.find(c => answerIDs.includes(c.id));
    if (doneAnswer) {
      answeredInChapter.push(doneAnswer);
      return true;
    }
    return false;
  });

  return ({
    chapter_id: chapter.id,
    is_finished: isFinished,
    finished_answers: answeredInChapter,
  });
};

export const capitalizeFirstLetter = (string, fallback = '') => {
  if (!string) {
    return fallback;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const mapTopologyTypesToSelector = (types) => {
  const list = [...types].reverse();
  return list.map((t, i) => ({
    ...t,
    value: t.name,
    label: `${capitalizeFirstLetter(t.name) || capitalizeFirstLetter(t.description)} - max ${t.capacity}`,
    index: i + 1,
  }));
};

export const hasUserAdminRole = (user) => {
  if (!user) {
    return false;
  }

  const rolesList = get(user, 'roles');
  const result = get(rolesList, 'admin') || get(rolesList, 'super_admin') || get(rolesList, 'reporter');
  return result;
};

export const isAdmin = (user) => {
  if (!user) {
    return false;
  }

  const rolesList = get(user, 'roles');
  const hasAdminRole = get(rolesList, 'admin');
  return hasAdminRole;
};

export const isSuperAdmin = (user) => {
  if (!user) {
    return false;
  }

  const rolesList = get(user, 'roles');
  const hasSuperAdminRole = get(rolesList, 'super_admin');
  return hasSuperAdminRole;
};

export const mapUserTopologySummary = (data = {}, topologies) => {
  const dataKeys = Object.keys(data);
  const result = dataKeys.map(k => {
    const topology = topologies.find(t => t.name === k);
    return ({
      name: k,
      count: data[k],
      topology,
    });
  });
  return result;
};

export const getTotalYoc = (data = {}) => {
  const dataKeys = Object.keys(data);
  return dataKeys.reduce((acc, key) => acc + data[key], 0);
};

export const getStatusFilterParam = (statusFilter) => {
  if (statusFilter === 'open') return '&closed=false';
  if (statusFilter === 'closed') return '&closed=true';
  return '';
};

export const getKYCStatusFilterParam = (statusFilter) => {
  if (statusFilter === 'validated') return '&status=validated';
  if (statusFilter === 'pending') return '&status=pending';
  if (statusFilter === 'failed') return '&status=failed';
  if (statusFilter === 'rejected') return '&status=rejected';
  return '';
};

export const getPriorityFilterParam = (priorityFilter) => {
  if (priorityFilter === 'critical') return '&severity=critical';
  if (priorityFilter === 'high') return '&severity=high';
  if (priorityFilter === 'normal') return '&severity=normal';
  if (priorityFilter === 'low') return '&severity=low';
  return '';
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const parseObject = (val, fallback = undefined) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return fallback || data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

export const getErrMsg = (err) => {
  const data = get(err, 'response.data');
  const dataJson = parseObject(data, {});
  return get(dataJson, 'message') || get(data, 'message') || '';
};

export const mapCountriesToSelector = list => list.map(i => ({
  data: i,
  value: i.iso3,
  label: i.name,
}));

export const mapCurrenciesToSelector = list => list.map(i => ({
  data: i,
  value: i.code,
  label: `${i.name} (${i.code} ${i.symbol})`,
}));

export const mapOrganizationsToSelector = (list = []) => {
  if (!list || !Array.isArray(list)) {
    return [];
  }
  return list.map(i => ({ value: i.id, label: i.name, data: i }));
};

export const getUserRoles = (user, isClient = true) => {
  const roles = get(user, 'roles') || {};
  const rolesOptions = [];
  if (isClient) {
    clientRoleOptions.forEach((r) => {
      if (roles[r.value]) {
        rolesOptions.push({ ...r, id: roles[r.value] });
      }
    });
  } else {
    adminRoleOptions.forEach((r) => {
      if (roles[r.value]) {
        rolesOptions.push({ ...r, id: roles[r.value] });
      }
    });
  }
  return rolesOptions;
};

export const getFileExtension = (fileName = '') => {
  const parts = fileName.split('.');
  const extension = parts[parts.length - 1];
  return extension;
};

export const getLink = (url) => {
  if (!url) { return ''; }
  const containProtocol = url.includes('http');
  if (!containProtocol) {
    return `//${url}`;
  }
  return url;
};

export const getEntityTypeLabel = (val) => {
  switch (val) {
    case 'business_entity':
      return 'Organization';
    case 'user_profile':
      return 'User profile';
    case 'offering_member':
      return 'Offering interest request';
    case 'offering_owner':
      return 'Offering owner';
    case 'user_role':
      return 'User role';
    default:
      return capitalizeFirstLetter(val) || '';
  }
};

export const dataURItoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]); } else { byteString = unescape(dataURI.split(',')[1]); }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // eslint-disable-next-line no-undef
  return new Blob([ia], { type: mimeString });
};
