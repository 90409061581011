import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { Button } from 'shared/components';
import {
  IconChevronDown,
  IconChevronUp,
  IconDelete,
  IconNoProfilePicture20,
} from 'shared/icons';
import { useWindowDimensions } from 'shared/HOCs';
import { clearNavigationHistory } from 'redux/navigation/actions';
import * as Styled from './styles';

const NavigationHistory = () => {
  const { isTablet } = useWindowDimensions();
  const history = useHistory();
  const dispatch = useDispatch();
  const navigationList = useSelector(state => get(state, 'navigationHistory.list') || []);

  const [isOpen, setOpen] = useState(!isTablet);

  const navigationHistoryClassName = () => {
    const isOpenClass = isOpen ? 'NavigationHistory-open' : 'NavigationHistory-closed';
    const isMobileClass = isTablet ? 'NavigationHistory-mobile' : 'NavigationHistory-desktop';
    return `${isOpenClass} ${isMobileClass}`;
  };

  const getHeaderIcon = () => {
    if (isOpen) {
      if (isTablet) {
        return <IconChevronDown />;
      }
      return <IconChevronUp />;
    }

    if (isTablet) {
      return <IconChevronUp />;
    }
    return <IconChevronDown />;
  };

  return (
    <Styled.NavigationHistory className={navigationHistoryClassName()}>
      <Styled.Header type="button" onClick={() => setOpen(!isOpen)}>
        <Styled.HeaderTitle>Navigation history</Styled.HeaderTitle>
        {getHeaderIcon()}
      </Styled.Header>
      {isOpen ? (
        <>
          <Styled.List>
            {navigationList.map((nav, i) => (
              <Styled.ListItem
                key={nav.user_id}
                tabindex="0"
                role="button"
                onClick={() => {
                  history.push(`/users/${nav.user_id}`);
                }}
              >
                <IconNoProfilePicture20 />
                <Styled.ListItemText>
                  {nav.full_name || nav.user_name}
                </Styled.ListItemText>
                {i < (navigationList.length - 1) && <Styled.ListItemLine />}
              </Styled.ListItem>
            ))}
          </Styled.List>
          <Styled.ListFooter>
            <Button disabled={!navigationList.length} variant="text" wide handleClick={() => dispatch(clearNavigationHistory())}>
              <Styled.BtnInner>
                <IconDelete />
                <Styled.BtnText>Delete history</Styled.BtnText>
              </Styled.BtnInner>
            </Button>
          </Styled.ListFooter>
        </>
      ) : null}
    </Styled.NavigationHistory>
  );
};

export default NavigationHistory;
