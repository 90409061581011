/* eslint-disable jsx-a11y/click-events-have-key-events  */
import { useState, useCallback, useEffect } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import {
  Button,
  Selector,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  UserInfoCell,
} from 'shared/components';
import api from 'shared/api';
import { getErrMsg } from 'shared/helpers';
import { IconUserAddFill, IconClose, IconDelete } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');
const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
    paddingTop: '40px',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const AddOfferingMemberForm = ({
  isOpen,
  closeCb,
  refetchMembers,
  offering,
}) => {
  const offeringID = get(offering, 'id');
  const offeringName = get(offering, 'name');
  const [usersSearch, setUsersSearch] = useState('');
  const [usersLoading, setUsersLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isLoading, setLoading] = useState(false);

  // bulk import
  const [bulkUsers, setBulkUsers] = useState([]);
  const [bulkUsersSearch, setBulkUsersSearch] = useState('');
  const [bulkSelectedUsers, setBulkSelectedUsers] = useState([]);

  const addMember = useCallback((postData) => {
    api.post(`/api/investing/offerings/${offeringID}/members`, postData)
      .then(() => {
        toast.success('Offering member added succesfully');
        setLoading(false);
        refetchMembers();
        closeCb();
      })
      .catch((err) => {
        const errMsg = getErrMsg(err);
        toast.error(errMsg || 'Error occured');
        setLoading(false);
      });
  }, [offeringID]);

  const addMembersInBulk = useCallback((postData) => {
    api.post(`/api/investing/offerings/${offeringID}/members/bulk`, postData)
      .then(() => {
        toast.success('Offering members added succesfully');
        setLoading(false);
        refetchMembers();
        closeCb();
      })
      .catch((err) => {
        const errMsg = getErrMsg(err);
        toast.error(errMsg || 'Error occured');
        setLoading(false);
      });
  }, [offeringID]);

  const onMemberSubmit = () => {
    if (isLoading) {
      return false;
    }
    if (!selectedUser || !selectedUser.length) {
      toast.error('Please select user');
      return false;
    }

    setLoading(true);
    const postData = {
      offering_name: offeringName,
      user_role_id: get(selectedUser, '[0].roles.maker'),
      status: 'approved',
    };
    addMember(postData);
    return true;
  };

  const onBulkMembersSubmit = () => {
    if (isLoading) {
      return false;
    }
    if (!bulkSelectedUsers || !bulkSelectedUsers.length) {
      toast.error('Please select users');
      return false;
    }

    setLoading(true);
    const postData = bulkSelectedUsers.map(u => ({
      user_role_id: get(u, 'roles.maker'),
      offering_name: offeringName,
      status: 'approved',
    }));
    addMembersInBulk(postData);
    return true;
  };

  const getInitialUsers = useCallback(() => {
    api.get('/api/user-management/user-profiles?page=1&per_page=1000&app_name=client&excluded_statuses=external&role_name=maker')
      .then((res) => {
        const list = get(res, 'data.records') || [];
        const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
        setUsers(mappedList);
        setBulkUsers(mappedList);
        setUsersLoading(false);
      })
      .catch(() => setUsersLoading(false));
  }, []);

  useEffect(() => {
    getInitialUsers();
  }, [getInitialUsers]);

  // const handleTabSelect = (index) => {
  //   if (index === 0) {
  //     setSelectedUser([]);
  //     setUsersSearch('');
  //   } else {
  //     setBulkSelectedUsers([]);
  //     setBulkUsersSearch('');
  //   }
  // };

  const handleMemberRemove = (userToRemove) => {
    const newList = bulkSelectedUsers.filter(bu => bu.user_id !== userToRemove.user_id);
    setBulkSelectedUsers(newList);
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="AddOfferingMemberFormModal"
    >
      <Styled.AddOfferingMemberForm>
        <Styled.Header>
          <IconUserAddFill />
          <Styled.HeaderTitle>Offering member import</Styled.HeaderTitle>
        </Styled.Header>
        {/* <Tabs onSelect={handleTabSelect}> */}
        <Tabs>
          <TabsHeader>
            <Tab>Single import</Tab>
            <Tab>Bulk import</Tab>
          </TabsHeader>
          <TabContent>
            <Styled.Form>
              <Styled.InputWrapper>
                <Selector
                  id="user-input"
                  name="selected-user"
                  label="Search user"
                  options={users}
                  onChange={(val) => {
                    setSelectedUser(val);
                  }}
                  values={selectedUser}
                  labelField="label"
                  valueField="value"
                  placeholder="Search..."
                  searchable
                  searchFn={({ state, methods }) => {
                    if (state.search !== usersSearch) {
                      setUsersSearch(state.search);
                      api.get(`/api/user-management/user-profiles?page=1&full_name=${methods.safeString(state.search)}&per_page=1000&app_name=client&excluded_statuses=external&role_name=maker`)
                        .then((res) => {
                          const list = get(res, 'data.records') || [];
                          const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
                          setUsers(mappedList);
                        })
                        .catch(() => []);
                    }
                  }}
                  disabled={usersLoading}
                />
              </Styled.InputWrapper>
              <Styled.ButtonContainer>
                <Button handleClick={onMemberSubmit} wide disabled={isLoading}>
                  Add member
                </Button>
              </Styled.ButtonContainer>
            </Styled.Form>
          </TabContent>
          <TabContent>
            <Styled.Form>
              <Styled.InputWrapper className="bulk-search-input">
                <Selector
                  id="user-input"
                  name="selected-user"
                  label="Search users"
                  options={bulkUsers}
                  onChange={(val) => {
                    setBulkSelectedUsers(val);
                  }}
                  values={bulkSelectedUsers}
                  labelField="label"
                  valueField="value"
                  placeholder="Search..."
                  addPlaceholder={bulkUsersSearch || 'Search...'}
                  multi
                  searchable
                  searchFn={({ state, methods }) => {
                    if (state.search !== bulkUsersSearch) {
                      setBulkUsersSearch(state.search);
                      api.get(`/api/user-management/user-profiles?page=1&full_name=${methods.safeString(state.search)}&per_page=1000&app_name=client&excluded_statuses=external&role_name=maker`)
                        .then((res) => {
                          const list = get(res, 'data.records') || [];
                          const mappedList = list.map(u => ({ ...u, value: u.user_id, label: u.full_name }));
                          setBulkUsers(mappedList);
                        })
                        .catch(() => []);
                    }
                  }}
                  disabled={usersLoading}
                />
              </Styled.InputWrapper>
              <Styled.BulkUsersList>
                {bulkSelectedUsers.map(u => (
                  <Styled.ClickableUserCell tabIndex={0} role="button" onClick={() => handleMemberRemove(u)}>
                    <UserInfoCell
                      profileImg={get(u, 'profile_picture')}
                      name={get(u, 'full_name')}
                      metaFirst={get(u, 'title')}
                    />
                    <Styled.RemoveMember className="member-delete"><IconDelete color="#e2665e" /></Styled.RemoveMember>
                  </Styled.ClickableUserCell>
                ))}
              </Styled.BulkUsersList>
              <Styled.ButtonContainer>
                <Button handleClick={onBulkMembersSubmit} wide disabled={isLoading}>
                  Bulk members import
                </Button>
              </Styled.ButtonContainer>
            </Styled.Form>
          </TabContent>
        </Tabs>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.AddOfferingMemberForm>
    </ReactModal>
  );
};

AddOfferingMemberForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchMembers: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
};

export default AddOfferingMemberForm;
