import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';
import { Button, Loader, UserInfoCell } from 'shared/components';
import { OrganizationCard } from 'Organizations/components';
import api from 'shared/api';
import * as Styled from './styles';

const OrganizationNotification = ({
  organization,
  notification,
}) => {
  const history = useHistory();
  const organizationID = get(organization, 'BusinessEntity.id') || '';
  const organizationName = get(organization, 'BusinessEntity.name') || '';
  const status = get(notification, 'object.status');

  // organization owners
  const [owners, setOwners] = useState([]);
  const [ownersLoading, setOwnersLoading] = useState(true);
  const [isLoading, setLoading] = useState(true);
  const [organizationData, setOrganizationData] = useState(null);

  const getOrganizationOwners = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}/owners`)
      .then((res) => {
        const data = get(res, 'data') || [];
        const expandedOwnerItems = data.map(i => ({ ...i, is_owner: true }));
        setOwners(expandedOwnerItems);
        setOwnersLoading(false);
      })
      .catch(() => {
        setOwnersLoading(false);
      });
  }, [organizationID]);

  const getOrganization = useCallback(() => {
    api.get(`/api/user-management/business-entities/${organizationID}`)
      .then((res) => {
        setOrganizationData(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [organizationID]);

  useEffect(() => {
    getOrganizationOwners();
    getOrganization();
  }, [getOrganizationOwners]);

  if (isLoading || ownersLoading) {
    return (
      <Styled.OrganizationNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.OrganizationNotification>
    );
  }

  const getNotificationText = () => {
    switch (status) {
      case 'submitted':
        return `KYB request for organization ${organizationName} has been sent to be reviewed.`;
      case 'validated':
        return `KYB request for organization ${organizationName} has been approved.`;
      case 'failed':
        return `KYB request for organization ${organizationName} has been denied.`;
      default:
        return `${organizationName} has been ${status}`;
    }
  };

  return (
    <Styled.OrganizationNotification>
      <Styled.Text>{getNotificationText()}</Styled.Text>
      <Styled.Owner>
        <span>Organization owner:</span>
        {/* THIS needs to go to /yoc on client and /users on admin app */}
        <Link to={`/users/${get(owners, '[0].profile.user_id')}`}>
          <UserInfoCell
            profileImg={get(owners, '[0].profile.profile_picture')}
            name={get(owners, '[0].profile.full_name')}
            metaFirst={get(owners, '[0].profile.user_name')}
          />
        </Link>
      </Styled.Owner>
      <Styled.Organization>
        <span>Organization:</span>
        <OrganizationCard organization={organizationData} onClick={() => history.push(`/organizations/${organizationID}`)} />
      </Styled.Organization>
      <Button
        size="large"
        handleClick={() => history.push(`/organizations/${organizationID}`)}
      >
        Go to organization page
      </Button>
    </Styled.OrganizationNotification>
  );
};

OrganizationNotification.propTypes = {
  organization: PropTypes.object.isRequired,
  notification: PropTypes.object.isRequired,
};

export default OrganizationNotification;
