import * as actionTypes from './actionTypes';

const initialState = {
  countries: [],
  countriesLoading: true,
  currencies: [],
  currenciesLoading: true,
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getCountriesInit:
      return { ...state, countriesLoading: true };
    case actionTypes.getCountriesSuccess:
      return {
        ...state,
        countries: action.countries,
        countriesLoading: false,
        error: undefined,
      };
    case actionTypes.getCountriesError:
      return { ...state, countries: [] };
    case actionTypes.getCurrenciesInit:
      return { ...state, currenciesLoading: true };
    case actionTypes.getCurrenciesSuccess:
      return {
        ...state,
        currencies: action.currencies,
        currenciesLoading: false,
        error: undefined,
      };
    case actionTypes.getCurrenciesError:
      return { ...state, currencies: [] };
    default:
      return { ...state };
  }
}
