import { Route, Switch } from 'react-router-dom';
import DealRoomPage from 'DealRooms/DealRoomPage';
import OfferingsList from './OfferingsList';
import OfferingPage from './OfferingPage';
import OfferingDataroom from './OfferingDataroom';

const Offerings = () => (
  <Switch>
    <Route exact path="/offerings" component={OfferingsList} />
    <Route exact path="/offerings/:offeringID" component={OfferingPage} />
    <Route exact path="/offerings/:offeringID/dataroom" component={OfferingDataroom} />
    <Route exact path="/offerings/:offeringID/dataroom/:dealRoomID" component={DealRoomPage} />
  </Switch>
);

export default Offerings;
