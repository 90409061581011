import PropTypes from 'prop-types';
import { NotFound } from 'shared/components';
import { ProfileCard, ProfileDetails } from './components';
import * as Styled from './styles';

const ProfilePage = ({
  user,
  userClickCb,
  isAuth,
  connection,
  refetchProfile,
  // inviter,
}) => {
  if (!user) {
    return <NotFound isPage={false} />;
  }

  return (
    <Styled.ProfilePage>
      <ProfileCard
        data={user}
        isAuth={isAuth}
        connection={connection}
        refetchProfile={refetchProfile}
        showActions
      />
      <Styled.ProfileExtra>
        <ProfileDetails data={user} userClickCb={userClickCb} isAuth={isAuth} />
      </Styled.ProfileExtra>
    </Styled.ProfilePage>
  );
};

ProfilePage.propTypes = {
  isAuth: PropTypes.bool,
  connection: PropTypes.object,
  user: PropTypes.object,
  userClickCb: PropTypes.func,
  refetchProfile: PropTypes.func,
};

ProfilePage.defaultProps = {
  isAuth: false,
  user: null,
  connection: null,
  userClickCb: () => {},
  refetchProfile: () => {},
};

export default ProfilePage;
