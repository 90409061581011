import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import {
  PageLoader,
  TextInput,
  Pagination,
} from 'shared/components';
import {
  IconSearch,
  IconPlus,
} from 'shared/icons';
import api from 'shared/api';
import { useWindowDimensions } from 'shared/HOCs';
import { OfferingCard } from '../../components';
import * as Styled from './styles';

const PastOfferings = ({
  onAddBtnClick,
}) => {
  const { isMobile } = useWindowDimensions();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [offerings, setOfferings] = useState([]);

  const {
    control,
    handleSubmit,
  } = useForm();

  const getOfferings = useCallback((
    searchQuery = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&name=${searchQuery}` : '';
    api.get(`/api/investing/offerings?page=${page + 1}${searchParam}&per_page=12&stage=settled`)
      .then((res) => {
        setPageCount(get(res, 'data.count_pages') || 1);
        setActivePage(page);
        const list = get(res, 'data.records') || [];
        setOfferings(list);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getOfferings();
  }, [getOfferings]);

  const onSearchSubmit = (data) => {
    const { search } = data;
    setActiveSearch(search);
    getOfferings(search, 0);
  };

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getOfferings(activeSearch, selected);
    }
  };

  if (isLoading) {
    return <PageLoader color="#616E7F" />;
  }

  return (
    <>
      <Styled.ListHeader>
        <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
          <Controller
            name="search"
            control={control}
            defaultValue={activeSearch}
            render={({ field }) => (
              <TextInput
                id="offerings-search"
                name="search"
                placeholder="Search by name"
                size="small"
                button={<IconSearch />}
                onBtnClick={handleSubmit(onSearchSubmit)}
                {...field}
              />
            )}
          />
        </Styled.ListSearch>
        <Styled.ListAction>
          <Styled.AddBtn type="button" onClick={onAddBtnClick}>
            <IconPlus />
          </Styled.AddBtn>
        </Styled.ListAction>
      </Styled.ListHeader>
      <Styled.List>
        {offerings.map(o => (
          <div key={o.id}>
            <OfferingCard
              offering={o}
              handleCardClick={() => history.push(`/offerings/${get(o, 'offering.id')}/`)}
            />
          </div>
        ))}
      </Styled.List>
      <Styled.ListPagination>
        <Pagination
          page={activePage}
          pageCount={pageCount}
          handlePageClick={changeListPage}
          pageRangeDisplayed={isMobile ? 1 : 3}
        />
      </Styled.ListPagination>
    </>
  );
};

PastOfferings.propTypes = {
  onAddBtnClick: PropTypes.func,
};

PastOfferings.defaultProps = {
  onAddBtnClick: () => {},
};

export default PastOfferings;
