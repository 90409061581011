// Fetching user
export const getUserInit = 'GET_USER_INIT';
export const getUserSuccess = 'GET_USER_SUCCESS';
export const getUserError = 'GET_USER_ERROR';
export const refreshUser = 'REFRESH_USER';

// Fetching user profile
export const getUserProfileInit = 'GET_USER_PROFILE_INIT';
export const getUserProfileSuccess = 'GET_USER_PROFILE_SUCCESS';
export const getUserProfileError = 'GET_USER_PROFILE_ERROR';
export const refreshUserProfile = 'REFRESH_USER_PROFILE';

// Login
export const loginInit = 'LOGIN_INIT';
export const loginSuccess = 'LOGIN_SUCCESS';
export const loginError = 'LOGIN_ERROR';

// Logout
export const logoutInit = 'LOGOUT_INIT';
export const logoutSuccess = 'LOGOUT_SUCCESS';
export const logoutError = 'LOGOUT_ERROR';

// Invitation
export const invitationSuccess = 'INVITATION_SUCCESS';
export const invitationError = 'INVITATION_ERROR';
