import styled from 'styled-components';

export const FilesPreview = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;

  button {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export const ImagesContainer = styled.div`

  ul.images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.images > li {
    position: relative;
    padding-top: 66%;
  }

  ul.images > li img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (max-width: 567px) {
    ul.images {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  .fullscreen-group {

    &::before {

    }

    .fullscreen-toggle {
      outline: none;

      svg {

        path {
          stroke: transparent;
          fill: ${props => props.theme.colors.shades.warm.dawn90};
        }
      }
    }

    .fullscreen-exit-btn {
      outline: none;

      svg {

        g {
          stroke: transparent;
          fill: ${props => props.theme.colors.shades.warm.dawn90};
        }
      }
    }
  }
`;
