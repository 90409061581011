import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import api from 'shared/api';
import { capitalizeFirstLetter } from 'shared/helpers';
import { useWindowDimensions } from 'shared/HOCs';
import {
  Button,
  PageLoader,
  Table,
  UserInfoCell,
} from 'shared/components';
import AddOfferingMemberForm from '../AddOfferingMemberForm';
import * as Styled from './styles';

const OfferingMembers = ({
  offering,
  isAuth,
}) => {
  const { isMobile } = useWindowDimensions();
  const history = useHistory();
  const offeringID = get(offering, 'id');

  const [isLoading, setLoading] = useState(true);
  const [isAddMemberDisplayed, setAddMemberDisplayed] = useState(false);
  const [members, setMembers] = useState([]);

  const getMembers = useCallback(() => {
    api.get(`/api/investing/offerings/${offeringID}/members`)
      .then((res) => {
        setMembers(get(res, 'data') || []);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [offeringID]);

  useEffect(() => {
    getMembers();
  }, [getMembers]);

  const tableData = useMemo(() => members, [members]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'profile.full_name',
      Cell: cell => (
        <UserInfoCell
          profileImg={get(cell, 'row.original.profile.profile_picture')}
          name={get(cell, 'value')}
          metaFirst={get(cell, 'row.original.profile.user_name')}
        />
      ),
      minWidth: 250,
    },
    {
      Header: 'Status',
      accessor: 'member.status',
      Cell: cell => capitalizeFirstLetter(cell.value),
    },
    {
      Header: 'Min investment',
      accessor: 'member.min_investment',
      Cell: cell => get(cell, 'value') || '-',
      show: !isMobile,
    },
    {
      Header: 'Max investment',
      accessor: 'member.max_investment',
      Cell: cell => get(cell, 'value') || '-',
      show: !isMobile,
    },
  ], [members]);

  const handleRowClick = row => {
    history.push(`/users/${get(row, 'profile.user_id')}`);
  };

  if (isLoading) {
    return <Styled.OfferingMembers><PageLoader /></Styled.OfferingMembers>;
  }

  return (
    <Styled.OfferingMembers>
      {isAuth && (
        <Button
          size="small"
          type="submit"
          handleClick={() => setAddMemberDisplayed(true)}
        >
          Add member
        </Button>
      )}
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="UsersListRow"
      />
      {isAddMemberDisplayed && (
        <AddOfferingMemberForm
          isOpen={isAddMemberDisplayed}
          closeCb={() => setAddMemberDisplayed(false)}
          offering={offering}
          refetchMembers={getMembers}
        />
      )}
    </Styled.OfferingMembers>
  );
};

OfferingMembers.propTypes = {
  offering: PropTypes.object.isRequired,
  isAuth: PropTypes.bool,
};

OfferingMembers.defaultProps = {
  isAuth: false,
};

export default OfferingMembers;
