import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import api from 'shared/api';
import toast from 'react-hot-toast';
import {
  Button,
  PageLoader,
  Page,
  Pagination,
  TextInput,
} from 'shared/components';
import {
  IconAdjustments,
  IconClientsFill,
  IconSearch,
  IconFilter,
  IconFilterOutline,
} from 'shared/icons';
import { useWindowDimensions } from 'shared/HOCs';
import { getKYCStatusFilterParam } from 'shared/helpers';
import UsersList from './UsersList';
import { UsersFiltersForm, SearchTypeForm } from './components';
import * as Styled from './styles';

const Users = () => {
  const { isMobile, isTablet } = useWindowDimensions();
  const initialFilters = { status: '' };

  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [activeSearch, setActiveSearch] = useState('');
  const [searchType, setSearchType] = useState('full_name');
  const [showSearchTypeForm, setSearchTypeForm] = useState(false);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const [showFiltersForm, setFiltersForm] = useState(false);

  const {
    control,
    handleSubmit,
  } = useForm();

  const getUsersList = useCallback((
    searchQuery = undefined,
    newFilters = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&${searchType}=${encodeURIComponent(searchQuery)}` : '';
    const statusFilterParam = getKYCStatusFilterParam(get(newFilters, 'status'));
    api.get(`/api/user-management/user-profiles?page=${page + 1}${searchParam}${statusFilterParam}&per_page=10`)
      .then((res) => {
        setUsers(get(res, 'data.records'));
        setPageCount(get(res, 'data.count_pages'));
        setActivePage(page);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [searchType]);

  const onSearchSubmit = (data) => {
    const { search } = data;
    setActiveSearch(search);
    getUsersList(search);
  };

  const handleFiltersChange = (newFilters) => {
    if (!newFilters) {
      setFiltersForm(false);
    } else {
      setFiltersForm(false);
      setFilters(newFilters);
    }
    getUsersList(activeSearch, newFilters, activePage);
  };

  const changeListPage = (data) => {
    const { selected } = data;
    if (selected !== activePage) {
      setActivePage(selected);
      getUsersList(activeSearch, filters, selected);
    }
  };

  useEffect(() => {
    getUsersList();
  }, [getUsersList]);

  const getFilterNotification = () => {
    if (!filters.status && !filters.priority) return null;
    let value;
    if (filters.status && filters.priority) {
      value = 2;
    } else {
      value = 1;
    }

    return <Styled.FilterNotification>{value}</Styled.FilterNotification>;
  };

  if (isLoading) {
    return (
      <Page type="clients" title="clients"><PageLoader /></Page>
    );
  }

  const searchPlaceholder = searchType === 'full_name' ? 'Search by name' : 'Search by email';

  return (
    <Page type="users" title="users">
      <Styled.UsersWrapper>
        <Styled.UsersList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconClientsFill /></Styled.TitleIcon>
              <Styled.TitleText>Users</Styled.TitleText>
            </Styled.ListTitle>
            <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
              <Button size="small" variant="outlined" handleClick={() => setSearchTypeForm(true)}>
                <IconAdjustments />
              </Button>
              <Controller
                name="search"
                control={control}
                defaultValue={activeSearch}
                render={({ field }) => (
                  <TextInput
                    id="clients-search"
                    placeholder={searchPlaceholder}
                    size="small"
                    button={<IconSearch />}
                    onBtnClick={handleSubmit(onSearchSubmit)}
                    {...field}
                  />
                )}
              />
            </Styled.ListSearch>
            <Styled.FilterBtn type="button" onClick={() => setFiltersForm(true)}>
              {isTablet ? <IconFilterOutline /> : <IconFilter />}
              <Styled.FilterBtnText>Filters</Styled.FilterBtnText>
              {getFilterNotification()}
            </Styled.FilterBtn>
          </Styled.ListHeader>
          <UsersList data={users} refreshUsers={getUsersList} />
          <Styled.ListPagination>
            <Pagination
              page={activePage}
              pageCount={pageCount}
              handlePageClick={changeListPage}
              pageRangeDisplayed={isMobile ? 1 : 3}
            />
          </Styled.ListPagination>
        </Styled.UsersList>
        {showFiltersForm && (
          <UsersFiltersForm
            isOpen={showFiltersForm}
            closeCb={handleFiltersChange}
            filters={filters}
          />
        )}
        {showSearchTypeForm && (
          <SearchTypeForm
            isOpen={showSearchTypeForm}
            closeCb={() => setSearchTypeForm(false)}
            confirmCb={(val) => {
              setSearchType(val);
              setSearchTypeForm(false);
            }}
            activeType={searchType}
          />
        )}
      </Styled.UsersWrapper>
    </Page>
  );
};

export default Users;
