import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import {
  Button,
  Checkbox,
  Notice,
  Loader,
  UserInfoCell,
} from 'shared/components';
import { IconWebsite } from 'shared/icons';
import { getUserRoles, getLink } from 'shared/helpers';
import api from 'shared/api';
import * as Styled from './styles';

const UserNotification = ({
  closeCb,
  user,
}) => {
  const history = useHistory();
  const userID = get(user, 'User.id') || '';
  const userName = get(user, 'User.user_name') || '';
  const status = get(user, 'User.status');

  const [isLoading, setLoading] = useState(false);
  const [isProfileLoading, setProfileLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [roles, setRoles] = useState(null);
  const [rolesLoading, setRolesLoading] = useState(true);
  const [approvedRoles, setApprovedRoles] = useState([]);
  const [approvedRolesError, setApprovedRolesError] = useState('');

  const acceptRequest = useCallback(() => {
    api.patch(`/api/user-management/users/${userID}/status`, { status: 'pending' })
      .then(() => {
        toast.success('Request approved');
        history.push(`/users/${userID}`);
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [userID]);

  const rejectRequest = useCallback(() => {
    setLoading(true);
    api.patch(`/api/user-management/users/${userID}/status`, { status: 'rejected' })
      .then(() => {
        toast.success('Request rejected');
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
  }, [userID]);

  const acceptUserRole = useCallback((roleName) => {
    const role = roles.find(r => r.value === roleName);
    const roleID = role?.id;
    return api.patch(`/api/user-management/user-roles/${roleID}/status`, { status: 'approved' });
  }, [roles]);

  const approveRoles = () => Promise.all(approvedRoles.map(acceptUserRole));

  const handleAccept = () => {
    if (!approvedRoles.length) {
      setApprovedRolesError('Select at least one role');
      return false;
    }
    setLoading(true);
    approveRoles()
      .then(() => {
        acceptRequest();
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  const getProfile = useCallback(() => {
    api.get(`/api/user-management/user-profiles/${userID}`)
      .then((res) => {
        setProfileData(get(res, 'data.user'));
        setProfileLoading(false);
      })
      .catch(() => {
        setProfileLoading(false);
      });
  }, [userID]);

  const getRoles = useCallback(() => {
    api.get(`/api/user-management/users/${userID}/roles?status=pending`)
      .then((res) => {
        const userRoles = { roles: get(res, 'data') };
        const rolesList = getUserRoles(userRoles, true);
        setRoles(rolesList);
        setApprovedRoles(rolesList.map(r => r.value));
        setRolesLoading(false);
      })
      .catch(() => {
        setRolesLoading(false);
      });
  }, [userID]);

  const handleRoleSelect = (role) => {
    setApprovedRolesError('');
    const isAdded = approvedRoles.find(ar => ar === role.value);
    if (isAdded) {
      const list = approvedRoles.filter(ar => ar !== role.value);
      setApprovedRoles(list);
    } else {
      const list = [...approvedRoles, role.value];
      setApprovedRoles(list);
    }
  };

  useEffect(() => {
    getProfile();
    getRoles();
  }, [getProfile, getRoles]);

  if (isProfileLoading || rolesLoading) {
    return (
      <Styled.UserNotification>
        <Loader color="#616E7F" width={20} height={20} />
      </Styled.UserNotification>
    );
  }

  const isAlreadyApproved = status !== 'external';
  const website = get(profileData, 'website');

  return (
    <Styled.UserNotification>
      <Styled.Text>{`External user ${userName} has requested access to the platform.`}</Styled.Text>
      <Styled.User>
        <span>User profile:</span>
        {/* THIS needs to go to /yoc on client and /users on admin app */}
        <Link to={`/users/${userID}`}>
          <UserInfoCell
            profileImg={get(profileData, 'profile_picture')}
            name={get(profileData, 'full_name')}
            metaFirst={get(profileData, 'user_name')}
          />
        </Link>
      </Styled.User>
      <Styled.UserBio>
        <span>User description:</span>
        <p>{get(profileData, 'bio')}</p>
        {website && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(website)}>
            <IconWebsite />
            <span>{website}</span>
          </Styled.ContactItem>
        )}
      </Styled.UserBio>
      <Styled.Roles>
        <Styled.RolesHeader>Approve requested roles</Styled.RolesHeader>
        {roles.map(r => (
          <Checkbox
            key={r.id}
            id={r.value}
            value={approvedRoles.includes(r.value)}
            label={r.label}
            onChange={() => handleRoleSelect(r)}
            disabled={isAlreadyApproved}
          />
        ))}
      </Styled.Roles>
      {approvedRolesError && <Styled.Error>{approvedRolesError}</Styled.Error>}
      {!isAlreadyApproved && (
        <Styled.ButtonsContainer>
          <Button disabled={isLoading} handleClick={handleAccept}>Accept</Button>
          <Button disabled={isLoading} handleClick={rejectRequest} variant="outlined">Reject</Button>
        </Styled.ButtonsContainer>
      )}
      {isAlreadyApproved && (
        <Styled.Approved>
          <Notice
            title="Request has been approved."
            type="success"
          />
        </Styled.Approved>
      )}
    </Styled.UserNotification>
  );
};

UserNotification.propTypes = {
  user: PropTypes.object.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default UserNotification;
