import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Button, SidePanel, PriorityCell } from 'shared/components';
import { IconClose, IconSupportFill } from 'shared/icons';
import { formatDate } from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import api from 'shared/api';
import * as Styled from './styles';

const TicketDetails = ({
  isOpen,
  onClose,
  ticket,
  refreshUsers,
}) => {
  const [ticketDetails, setTicketDetails] = useState(ticket);

  const ticketID = get(ticketDetails, 'ticket.id');
  const isTicketSeen = get(ticketDetails, 'ticket.seen');
  const isTicketClosed = get(ticketDetails, 'ticket.closed');

  const setTicketAsSeen = useCallback(() => {
    const formData = { name: 'Seen', value: true };
    api.patch(`/api/user-management/tickets/${ticketID}/status`, formData)
      .then((res) => {
        const data = get(res, 'data');
        const newTicketState = { ...ticketDetails, ticket: data };
        setTicketDetails(newTicketState);
        refreshUsers();
      })
      .catch(() => {});
  }, [ticketID]);

  const setTicketAsClosed = useCallback(() => {
    const formData = { name: 'Closed', value: true };
    api.patch(`/api/user-management/tickets/${ticketID}/status`, formData)
      .then((res) => {
        const data = get(res, 'data');
        const newTicketState = { ...ticketDetails, ticket: data };
        setTicketDetails(newTicketState);
        refreshUsers();
      })
      .catch(() => {});
  }, [ticketID]);

  const setTicketAsOpen = useCallback(() => {
    const formData = { name: 'Closed', value: false };
    api.patch(`/api/user-management/tickets/${ticketID}/status`, formData)
      .then((res) => {
        const data = get(res, 'data');
        const newTicketState = { ...ticketDetails, ticket: data };
        setTicketDetails(newTicketState);
        refreshUsers();
      })
      .catch(() => {});
  }, [ticketID]);

  useEffect(() => {
    if (!isTicketSeen) {
      setTicketAsSeen();
    }
  }, [setTicketAsSeen]);

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.TicketDetails>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconSupportFill />
            <Styled.HeaderTitleText>{`Ticket #${ticketDetails.ticket.id}`}</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.DetailsInner>
          <Styled.DetailsRow>
            <Styled.RowLabel>Created:</Styled.RowLabel>
            <Styled.RowValue>{formatDate(get(ticketDetails, 'ticket.created_at'), defaultDateTimeFormat)}</Styled.RowValue>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.RowLabel>Priority:</Styled.RowLabel>
            <PriorityCell value={get(ticketDetails, 'ticket.severity')} isLite />
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.RowLabel>User:</Styled.RowLabel>
            <Styled.RowValue>{get(ticketDetails, 'user_name')}</Styled.RowValue>
          </Styled.DetailsRow>
          {isTicketClosed ? (
            <Button wide size="small" handleClick={setTicketAsOpen}>
              Reopen
            </Button>
          ) : (
            <Button wide variant="outlined" size="small" handleClick={setTicketAsClosed}>
              Mark as closed
            </Button>
          )}
        </Styled.DetailsInner>
        <Styled.MessageContainer>
          <Styled.Message>
            <Styled.MessageText>
              {get(ticketDetails, 'ticket.text')}
            </Styled.MessageText>
          </Styled.Message>
        </Styled.MessageContainer>
      </Styled.TicketDetails>
    </SidePanel>
  );
};

TicketDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshUsers: PropTypes.func.isRequired,
  ticket: PropTypes.object.isRequired,
};

export default TicketDetails;
