import styled from 'styled-components';

export const InvitationNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Text = styled.div``;

export const Inviter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    display: flex;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: auto;
`;

export const Expired = styled.div`
  margin-top: 20px;
`;
