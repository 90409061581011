/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {
  IconEmail,
  IconPhone,
  IconWebsite,
  IconFacebook,
  IconLinkedin,
  IconTwitter,
} from 'shared/icons';
import { getLink } from 'shared/helpers';
import * as Styled from './styles';

const ProfileOverview = ({
  userProfile,
}) => {
  const {
    full_name,
    // birthday,
    // gender,
    // address,
    email,
    phone,
    website,
    facebook_url,
    linkedin_url,
    twitter_url,
    bio,
  } = userProfile;

  return (
    <Styled.ProfileOverview>
      <Styled.Label>Name</Styled.Label>
      <Styled.Value>{full_name || '-'}</Styled.Value>
      {/* <Styled.Label>Birthday</Styled.Label>
      <Styled.Value>{formatDate(birthday) || '-'}</Styled.Value>
      <Styled.Label>Gender</Styled.Label>
      <Styled.Value>{capitalizeFirstLetter(gender, '-')}</Styled.Value>
      <Styled.Label>Address</Styled.Label>
      <Styled.Value>{address || '-'}</Styled.Value> */}
      <Styled.ContactContainer>
        {email && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
            <IconEmail />
            <span>{email}</span>
          </Styled.ContactItem>
        )}
        {phone && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={`tel:${phone}`}>
            <IconPhone />
            <span>{phone}</span>
          </Styled.ContactItem>
        )}
        {website && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(website)}>
            <IconWebsite />
          </Styled.ContactItem>
        )}
        {facebook_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(facebook_url)}>
            <IconFacebook />
          </Styled.ContactItem>
        )}
        {twitter_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(twitter_url)}>
            <IconTwitter />
          </Styled.ContactItem>
        )}
        {linkedin_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(linkedin_url)}>
            <IconLinkedin />
          </Styled.ContactItem>
        )}
      </Styled.ContactContainer>
      <Styled.Description>{bio || ''}</Styled.Description>
    </Styled.ProfileOverview>
  );
};

ProfileOverview.propTypes = {
  userProfile: PropTypes.object.isRequired,
};

export default ProfileOverview;
