import {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import {
  Page,
  PageLoader,
  Pagination,
  Table,
  TextInput,
} from 'shared/components';
import { IconBell, IconSearch } from 'shared/icons';
import { formatDate, formatDateDistance, getEntityTypeLabel } from 'shared/helpers';
import { useWindowDimensions } from 'shared/HOCs';
import api from 'shared/api';
import NotificationDetails from '../NotificationDetails';
import * as Styled from './styles';

const NotificationsList = () => {
  const { isMobile } = useWindowDimensions();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isNotificationDisplayed, setNotificationDisplay] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [entities, setEntities] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);

  const {
    control,
    handleSubmit,
  } = useForm();

  const getNotifications = useCallback((
    searchQuery = undefined,
    page = 0,
  ) => {
    // eslint-disable-next-line no-unused-vars
    const searchParam = searchQuery ? `&name=${searchQuery}` : '';
    api.get(`/api/notifying/users/${userID}/notifications?page=${page + 1}&per_page=10`)
      .then((res) => {
        setPageCount(get(res, 'data.objects.count_pages') || 1);
        setActivePage(page);
        const entitiesList = get(res, 'data.entities') || [];
        const list = get(res, 'data.objects.records') || [];
        setNotifications(list);
        setEntities(entitiesList);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  const onSearchSubmit = (data) => {
    const { search } = data;
    setActiveSearch(search);
    getNotifications(search, 0);
  };

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getNotifications(activeSearch, selected);
    }
  };

  const getInvitationDetails = (uuid, status) => {
    const isApproved = status === 'approved';
    const entity = entities.find(e => get(e, 'Invitation.id') === uuid);
    const inviter = get(entity, 'Invitation.inviter_full_name') || get(entity, 'Invitation.invitee') || '';
    const invitee = get(entity, 'Invitation.invitee_full_name') || get(entity, 'Invitation.invitee') || '';
    const actor = isApproved ? invitee : inviter;
    const expiresAt = get(entity, 'Invitation.expires_at') || '';
    return actor || `Expires at ${formatDate(expiresAt)}`;
  };

  const getOrganizationName = (id) => {
    const entity = entities.find(e => get(e, 'BusinessEntity.id') === id);
    const name = get(entity, 'BusinessEntity.name') || '';
    return name;
  };

  const getOfferingMemberDetails = (id) => {
    const entity = entities.find(e => get(e, 'OfferingMember.member.id') === id);
    const name = get(entity, 'OfferingMember.profile.full_name') || '';
    return name;
  };

  const getUserDetails = (id) => {
    const entity = entities.find(e => get(e, 'User.id') === id);
    const name = get(entity, 'User.user_name') || '';
    return name;
  };

  const getDetails = (type, id, uuid, status) => {
    if (type === 'business_entity') {
      return getOrganizationName(id);
    }
    if (type === 'invitation') {
      return getInvitationDetails(uuid, status);
    }
    if (type === 'offering_member') {
      return getOfferingMemberDetails(id);
    }
    if (type === 'user') {
      return getUserDetails(id);
    }
    return type;
  };

  const handleRowClick = row => {
    setSelectedNotification(row);
    setNotificationDisplay(true);
  };

  const tableData = useMemo(() => notifications, [notifications]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Created',
      accessor: 'object.created_at',
      Cell: cell => {
        const { value } = cell;
        return formatDateDistance(value);
      },
      show: !isMobile,
      maxWidth: 100,
    },
    {
      Header: 'Event',
      accessor: 'object.entity_type',
      Cell: cell => {
        const entityType = get(cell, 'cell.row.original.object.entity_type');
        const status = get(cell, 'cell.row.original.object.status');
        return `${getEntityTypeLabel(entityType)} ${status}`;
      },
    },
    {
      Header: 'Details',
      accessor: 'object.entity_id',
      Cell: cell => {
        const entityType = get(cell, 'cell.row.original.object.entity_type');
        const entityID = get(cell, 'cell.row.original.object.entity_id');
        const entityUUID = get(cell, 'cell.row.original.object.entity_uuid');
        const status = get(cell, 'cell.row.original.object.status');
        return getDetails(entityType, entityID, entityUUID, status);
      },
    },
    {
      Header: '',
      accessor: 'actor.status',
      Cell: cell => {
        const { value } = cell;
        if (value === 'pending') {
          return <IconBell />;
        }
        return null;
      },
      className: 'notification',
      width: 30,
    },
  ], [isMobile, entities]);

  if (isLoading) {
    return <Page type="notifications" title="Notifications"><PageLoader color="#616E7F" /></Page>;
  }

  return (
    <Page type="notifications" title="Notifications">
      <Styled.NotificationsWrapper>
        <Styled.NotificationsList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconBell height={14} width={14} color="#20201E" /></Styled.TitleIcon>
              <Styled.TitleText>Notifications</Styled.TitleText>
            </Styled.ListTitle>
            {false && (
              <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
                <Controller
                  name="search"
                  control={control}
                  defaultValue={activeSearch}
                  render={({ field }) => (
                    <TextInput
                      id="offerings-search"
                      name="search"
                      placeholder="Search by name"
                      size="small"
                      button={<IconSearch />}
                      onBtnClick={handleSubmit(onSearchSubmit)}
                      {...field}
                    />
                  )}
                />
              </Styled.ListSearch>
            )}
          </Styled.ListHeader>
          <Table
            columns={tableColumns}
            data={tableData}
            isClickable
            onFetchData={() => { }}
            onRowClick={handleRowClick}
            rowClassName="NotificationListRow"
          />
          <Styled.ListPagination>
            <Pagination
              page={activePage}
              pageCount={pageCount}
              handlePageClick={changeListPage}
              pageRangeDisplayed={isMobile ? 1 : 3}
            />
          </Styled.ListPagination>
        </Styled.NotificationsList>
        {isNotificationDisplayed && (
          <NotificationDetails
            isOpen={isNotificationDisplayed}
            onClose={() => {
              setSelectedNotification(null);
              setNotificationDisplay(false);
            }}
            entities={entities}
            notification={selectedNotification}
            refreshNotifications={getNotifications}
          />
        )}
      </Styled.NotificationsWrapper>
    </Page>
  );
};

export default NotificationsList;
