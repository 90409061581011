import { useState } from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { Banner, Button, Page } from 'shared/components';
import InviteClientForm from './components/InviteClientForm';
import InviteAdminForm from './components/InviteAdminForm';
import NotificationsCard from './components/NotificationsCard';
import * as Styled from './styles';

const Dashboard = () => {
  const user = useSelector(state => get(state, 'account.user'));
  const userProfile = useSelector(state => get(state, 'account.userProfile'));
  const userFullName = get(userProfile, 'full_name');
  const userName = get(user, 'user_name');

  const [isInvitationFormDisplayed, setInvitationFormDisplayed] = useState(false);
  const [isRegisterFormDisplayed, setRegisterFormDisplayed] = useState(false);

  return (
    <Page type="dashboard" title="dashboard">
      <Styled.Dashboard>
        <Banner
          title={`Hi, ${userFullName || userName}`}
          text=""
        />
        <NotificationsCard />
        <Styled.Actions>
          <Button handleClick={() => setInvitationFormDisplayed(true)} wide>Invite user</Button>
          <Button handleClick={() => setRegisterFormDisplayed(true)} variant="outlined" wide>Add admin</Button>
        </Styled.Actions>
        {isInvitationFormDisplayed && (
          <InviteClientForm
            isOpen={isInvitationFormDisplayed}
            closeCb={() => setInvitationFormDisplayed(false)}
          />
        )}
        {isRegisterFormDisplayed && (
          <InviteAdminForm
            isOpen={isRegisterFormDisplayed}
            closeCb={() => setRegisterFormDisplayed(false)}
          />
        )}
      </Styled.Dashboard>
    </Page>
  );
};

export default Dashboard;
