/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import {
  IconEmail,
  IconPhone,
  IconWebsite,
  IconFacebook,
  IconTwitter,
  IconLinkedin,
} from 'shared/icons';
import { getLink } from 'shared/helpers';
import * as Styled from './styles';

const OrganizationOverview = ({
  organization,
}) => {
  const {
    address,
    email,
    phone,
    website,
    facebook_url,
    twitter_url,
    linkedin_url,
    bio,
  } = organization;

  return (
    <Styled.OrganizationOverview>
      <Styled.Label>Address</Styled.Label>
      <Styled.Value>{address || 'N/A'}</Styled.Value>
      {/* <Styled.Label>Business registration number</Styled.Label>
      <Styled.Value>{registration_nr || 'N/A'}</Styled.Value> */}
      <Styled.ContactContainer>
        {email && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={`mailto:${email}`}>
            <IconEmail />
            <span>{email}</span>
          </Styled.ContactItem>
        )}
        {phone && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={`tel:${phone}`}>
            <IconPhone />
            <span>{phone}</span>
          </Styled.ContactItem>
        )}
        {website && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(website)}>
            <IconWebsite />
          </Styled.ContactItem>
        )}
        {facebook_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(facebook_url)}>
            <IconFacebook />
          </Styled.ContactItem>
        )}
        {twitter_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(twitter_url)}>
            <IconTwitter />
          </Styled.ContactItem>
        )}
        {linkedin_url && (
          <Styled.ContactItem target="_blank" rel="noopener noreferrer" href={getLink(linkedin_url)}>
            <IconLinkedin />
          </Styled.ContactItem>
        )}
      </Styled.ContactContainer>
      <Styled.Description>{bio || 'Description N/A'}</Styled.Description>
    </Styled.OrganizationOverview>
  );
};

OrganizationOverview.propTypes = {
  organization: PropTypes.object.isRequired,
};

export default OrganizationOverview;
