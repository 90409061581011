import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import YocConnections from 'Yoc/YocConnections';
import { Section } from 'shared/components';
import { getUserRoles } from 'shared/helpers';
import ProfileOverview from '../ProfileOverview';

const ProfileDetails = ({
  data,
  userClickCb,
  isAuth,
}) => {
  const user = useSelector(state => get(state, 'account.user'));
  const userRoles = getUserRoles(user, false);
  const rolesContent = userRoles.map(ur => ur.label).join(', ');

  return (
    <>
      <Section
        content={<ProfileOverview userProfile={data} />}
      />
      {isAuth && (
        <Section
          title="User roles"
          content={rolesContent}
        />
      )}
      {false && (
        <Section
          title="Connections"
          content={(
            <YocConnections
              userID={get(data, 'user_id')}
              userClickCb={userClickCb}
            />
          )}
        />
      )}
    </>
  );
};

ProfileDetails.propTypes = {
  data: PropTypes.object.isRequired,
  userClickCb: PropTypes.func,
  isAuth: PropTypes.bool,
};

ProfileDetails.defaultProps = {
  userClickCb: () => {},
  isAuth: false,
};

export default ProfileDetails;
