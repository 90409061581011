import styled from 'styled-components';

export const Surveys = styled.div`
  background: transparent;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  width: 100%;
  max-width: 600px;
  margin-bottom: 20px;
`;

export const SurveysIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    flex-shrink: 0;
    margin-right: 14px;
  }
`;

export const SurveysData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Label = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Value = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;
