import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  TextArea,
  Selector,
} from 'shared/components';
import { mapCountriesToSelector } from 'shared/helpers';
import { IconUser, IconClose } from 'shared/icons';
import api from 'shared/api';
import { refreshUserProfile } from 'redux/account/actions';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const UserProfileForm = ({
  isOpen,
  closeCb,
  user,
  refetchUser,
}) => {
  const dispatch = useDispatch();
  const userID = get(user, 'user_id');
  const countries = useSelector(state => get(state, 'investing.countries'));

  const countriesOptions = mapCountriesToSelector(countries);
  const residence = countriesOptions.find(c => get(c, 'data.id') === get(user, 'country_of_residence'));
  const initialResidence = residence ? [residence] : [];

  const [isLoading, setLoading] = useState(false);
  const [countryOfResidence, setCountryOfResidence] = useState(initialResidence);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      dirtyForm: '',
      full_name: get(user, 'full_name') || '',
      bio: get(user, 'bio') || '',
      title: get(user, 'title') || '',
      email: get(user, 'email') || '',
      phone: get(user, 'phone') || '',
      city: get(user, 'city') || '',
      website: get(user, 'website') || '',
      linkedin_url: get(user, 'linkedin_url') || '',
      twitter_url: get(user, 'twitter_url') || '',
      facebook_url: get(user, 'facebook_url') || '',
    },
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    setLoading(true);
    const postData = {
      full_name: get(data, 'full_name') || '',
      bio: get(data, 'bio') || '',
      title: get(data, 'title') || '',
      email: get(data, 'email') || '',
      phone: get(data, 'phone') || '',
      city: get(data, 'city') || '',
      country_of_residence: get(countryOfResidence, '[0].data.id') || undefined,
      website: get(data, 'website') || '',
      linkedin_url: get(data, 'linkedin_url') || '',
      twitter_url: get(data, 'twitter_url') || '',
      facebook_url: get(data, 'facebook_url') || '',
    };
    api.patch(`/api/user-management/user-profiles/${userID}`, postData)
      .then((res) => {
        dispatch(refreshUserProfile(res.data));
        refetchUser();
        toast.success('Profile updated succesfully');
        setLoading(false);
        closeCb();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Error occured');
      });
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="UserProfileFormModal"
    >
      <Styled.UserProfileForm>
        <Styled.Main>
          <Styled.Header>
            <IconUser color="#20201E" />
            <Styled.HeaderTitle>Edit profile data</Styled.HeaderTitle>
          </Styled.Header>
          <Styled.Form onSubmit={handleSubmit(onSubmit)}>
            <Styled.InputContainer>
              <Controller
                name="full_name"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="full_name"
                    label="Name"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.full_name?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="title"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="title"
                    label="Title"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.title?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="bio"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextArea
                    id="bio"
                    label="Bio"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.bio?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="city"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="city"
                    label="City"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.city?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Selector
                id="residence-country-input"
                name="country_of_residence"
                label="Country"
                placeholder="Select country"
                options={countriesOptions}
                onChange={(val) => {
                  setValue('dirtyForm', 'true', { shouldDirty: true });
                  setCountryOfResidence(val);
                }}
                values={countryOfResidence}
                labelField="label"
                valueField="value"
                searchable
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="email"
                    type="email"
                    label="Email"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.email?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="phone"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="phone"
                    type="phone"
                    label="Phone"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.phone?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="website"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="website"
                    label="Website"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.website?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="linkedin_url"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="linkedin_url"
                    label="LinkedIn URL"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.linkedin_url?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="facebook_url"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="facebook_url"
                    label="Facebook URL"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.facebook_url?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.InputContainer>
              <Controller
                name="twitter_url"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="twitter_url"
                    label="Twitter URL"
                    placeholder=""
                    wide
                    disabled={isLoading}
                    error={errors.twitter_url?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
            <Styled.ButtonContainer>
              <Button
                type="submit"
                wide
                disabled={isLoading}
              >
                Submit
              </Button>
            </Styled.ButtonContainer>
          </Styled.Form>
        </Styled.Main>
      </Styled.UserProfileForm>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

UserProfileForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserProfileForm;
