import { useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams, useHistory } from 'react-router-dom';
import {
  Button,
  Page,
  Loader,
  Subtitle,
  TextInput,
  Selector,
  DatePicker,
  UserInfoCell,
} from 'shared/components';
import { IconUser, IconEdit, IconDelete } from 'shared/icons';
import { genderOptions, clientRoleOptions, adminRoleOptions } from 'shared/constants';
import {
  isSuperAdmin,
  mapCountriesToSelector,
  getUserRoles,
  hasUserAdminRole,
} from 'shared/helpers';
import api from 'shared/api';
import { appendToNavigationHistory } from 'redux/navigation/actions';
import YocCircles from 'Yoc/YocCircles';
import { ChangeProfilePictureForm } from 'ProfilePage/components';
import {
  AddRoleForm,
  BankAccountForm,
  Files,
  NavigationHistory,
  Surveys,
} from '../components';
import * as Styled from './styles';

const getUserGender = (initial) => {
  if (!initial) {
    return [genderOptions[2]];
  }
  const option = genderOptions.find(o => o.value === initial);
  return [option];
};

const validationSchema = yup.object().shape({
  name: yup
    .string(),
});

const ClientPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const { userID } = params;
  const currentUser = useSelector(state => get(state, 'account.user'));
  const countries = useSelector(state => get(state, 'investing.countries'));

  const countriesOptions = mapCountriesToSelector(countries);

  const [isLoading, setLoading] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [files, setFiles] = useState([]);
  const [filesLoading, setFilesLoading] = useState(null);
  const [surveys, setSurveys] = useState([]);
  const [surveysLoading, setSurveysLoading] = useState(null);
  const [profileData, setProfileData] = useState(null);
  const [isChangeImageDisplayed, setChangeImageDisplayed] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [showBankForm, setBankFormDisplay] = useState(false);
  const [bankAccountToEdit, setBankAccountToEdit] = useState(null);
  const [selectedGender, setSelectedGender] = useState([genderOptions[2]]);
  const [countryOfResidence, setCountryOfResidence] = useState([]);
  // featured
  const [featuredLoading, setFeaturedLoading] = useState(true);
  const [featured, setFeatured] = useState([]);
  // roles
  const [isAdmin, setAdmin] = useState(false);
  const [roles, setRoles] = useState([]);
  const [roleChanging, setRoleChanging] = useState(false);
  const [isAddRoleDisplayed, setAddRoleDisplay] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      username: get(profileData, 'user_name') || '',
      name: get(profileData, 'full_name') || '',
      birthday: get(profileData, 'birthday') || null,
      city: get(profileData, 'city') || '',
      pin: get(profileData, 'pin') || '',
      address: get(profileData, 'address') || '',
      title: get(profileData, 'title') || '',
      email: get(profileData, 'email') || '',
      phone: get(profileData, 'phone') || '',
    },
  });

  const resetValues = (userData) => {
    setValue('username', get(userData, 'user_name') || '');
    setValue('name', get(userData, 'full_name') || '');
    setSelectedGender(getUserGender(get(userData, 'gender')) || '');
    setValue('birthday', get(userData, 'birthday') || null);
    setValue('city', get(userData, 'city') || '');
    setValue('pin', get(userData, 'pin') || '');
    setValue('address', get(userData, 'address') || '');
    setValue('title', get(userData, 'title') || '');
    setValue('email', get(userData, 'email') || '');
    setValue('phone', get(userData, 'phone') || '');
  };

  const getUser = useCallback(() => {
    setLoading(true);
    api.get(`/api/user-management/user-profiles/${userID}`)
      .then((res) => {
        const userData = get(res, 'data.user');
        const residence = countriesOptions.find(c => get(c, 'data.id') === get(userData, 'country_of_residence'));
        const initialResidence = residence ? [residence] : [];
        setCountryOfResidence(initialResidence);
        setProfileData(userData);
        setSelectedGender(getUserGender(get(userData, 'gender')));
        resetValues(userData);
        setBankAccounts(get(res, 'data.bank_accounts'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID]);

  const getUserFiles = useCallback(() => {
    api.get(`/api/user-management/users/${userID}/files`)
      .then((res) => {
        const data = get(res, 'data') || [];
        setFiles(data);
        setFilesLoading(false);
      })
      .catch(() => {
        setFilesLoading(false);
      });
  }, [userID]);

  const getRoles = useCallback(() => {
    api.get(`/api/user-management/users/${userID}/roles?status=approved`)
      .then((res) => {
        const rolesResponse = get(res, 'data');
        const userRoles = { roles: rolesResponse };
        const isUserAdmin = hasUserAdminRole(userRoles);
        const rolesList = getUserRoles(userRoles, !isUserAdmin);
        setAdmin(isUserAdmin);
        setRoles(rolesList);
      })
      .catch(() => {
        setRoles([]);
      });
  }, [userID]);

  const getUserFeatured = useCallback(() => {
    api.get(`/api/user-management/user-tags?user_id=${userID}&tag_title=featured&page=1&per_page=100`)
      .then((res) => {
        const list = get(res, 'data.records') || [];
        setFeatured(list);
        setFeaturedLoading(false);
      })
      .catch(() => {
        setFeaturedLoading(false);
      });
  }, [userID]);

  const getUserSurveys = useCallback(() => {
    api.get(`/api/profiling/user-questionnaires/users/${userID}`)
      .then((res) => {
        const data = get(res, 'data') || [];
        setSurveys(data);
        setSurveysLoading(false);
      })
      .catch(() => {
        setSurveysLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    getUser();
    getUserFiles();
    getUserSurveys();
    getUserFeatured();
    getRoles();
  }, [
    getUserFiles,
    getUser,
    getUserSurveys,
    getUserFeatured,
    getRoles,
  ]);

  const handleFeatured = useCallback(() => {
    api.post(`/api/user-management/users/${userID}/tags`, { tag_title: 'featured' })
      .then(() => {
        getUserFeatured();
        toast.success('User set as featured');
      })
      .catch(() => {
        toast.error('Error occured');
      });
  }, [userID]);

  const handleFeaturedDelete = useCallback(() => {
    const tagID = get(featured, '[0].id');
    api.delete(`/api/user-management/user-tags/${tagID}`)
      .then(() => {
        getUserFeatured();
        toast.success('User removed from featured');
      })
      .catch(() => {
        toast.error('Error occured');
      });
  }, [featured]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const formData = {
      full_name: data.name,
      gender: get(selectedGender, '[0].value') || 'unknown',
      birthday: data.birthday,
      city: data.city,
      pin: data.pin,
      address: data.address || undefined,
      title: data.title || undefined,
      email: data.email || undefined,
      phone: data.phone || undefined,
      country_of_residence: get(countryOfResidence, '[0].data.id') || undefined,
    };

    setSaving(true);

    api.patch(`/api/user-management/user-profiles/${userID}`, formData)
      .then((res) => {
        const response = get(res, 'data');
        setProfileData({ ...profileData, ...response });
        toast.success('User profile updated');
        setSaving(false);
      })
      .catch(() => {
        toast.error('Error occured');
        setSaving(false);
      });
    return true;
  };

  const handleRoleDelete = (role) => {
    if (roleChanging) {
      return false;
    }
    if (roles.length <= 1) {
      toast.error('User needs to have at least one role');
      return false;
    }
    setRoleChanging(true);
    const data = {
      user_role_ids: [get(role, 'id')],
    };
    api.delete('/api/user-management/user-roles/bulk', data)
      .then(() => {
        getRoles();
        setRoleChanging(false);
        toast.success('User roles updated');
      })
      .catch(() => {
        setRoleChanging(false);
        toast.error('Error occured');
      });
    return true;
  };

  if (isLoading || filesLoading || featuredLoading) {
    return (
      <Page type="user" title="user">
        <Styled.ClientPageWrapper>
          <Styled.ClientPage>
            <Styled.ClientPageHeader>
              <Styled.Title>
                <Styled.TitleIcon><IconUser color="#616E7F" /></Styled.TitleIcon>
                <Styled.TitleText>User</Styled.TitleText>
              </Styled.Title>
            </Styled.ClientPageHeader>
            <Styled.ClientPageMain>
              <Loader color="#616E7F" />
            </Styled.ClientPageMain>
          </Styled.ClientPage>
          <NavigationHistory />
        </Styled.ClientPageWrapper>
      </Page>
    );
  }

  const superAdmin = isSuperAdmin(currentUser);
  const rolesOptions = isAdmin ? adminRoleOptions : clientRoleOptions;
  const remainingRoles = rolesOptions.filter((cro) => {
    const doesExist = roles.find(r => r.value === cro.value);
    if (doesExist) {
      return false;
    }
    return true;
  });

  return (
    <Page type="user" title="user">
      <Styled.ClientPageWrapper>
        <Styled.ClientPage>
          <Styled.ClientPageHeader>
            <Styled.Title>
              <Styled.TitleIcon><IconUser color="#616E7F" /></Styled.TitleIcon>
              <Styled.TitleText>User</Styled.TitleText>
            </Styled.Title>
          </Styled.ClientPageHeader>
          <Styled.ClientPageMainWrapper>
            <Styled.ClientPageMain>
              <Styled.Header>
                <Styled.ProfileName>
                  <UserInfoCell
                    profileImg={get(profileData, 'profile_picture')}
                    name={get(profileData, 'full_name') || ''}
                    metaFirst={get(profileData, 'title') || ''}
                    metaSecond={get(profileData, 'status') || ''}
                  />
                </Styled.ProfileName>
              </Styled.Header>
              <Styled.MainSection>
                <Styled.Form onSubmit={handleSubmit(onSubmit)}>
                  <Subtitle text="Personal details" />
                  <Styled.SingleInputRow>
                    <Controller
                      name="username"
                      control={control}
                      defaultValue={get(profileData, 'user_name') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="username"
                          label="Username"
                          placeholder="e.g. john@smith.com"
                          wide
                          disabled
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="name"
                      control={control}
                      defaultValue={get(profileData, 'full_name') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="name"
                          label="Name"
                          placeholder="e.g. John Smith"
                          wide
                          disabled={isSaving}
                          error={errors.name?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="title"
                      control={control}
                      defaultValue={get(profileData, 'title') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="title"
                          label="Title"
                          placeholder="e.g. CEO"
                          wide
                          disabled={isSaving}
                          error={errors.title?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.DualInputRow>
                    <Selector
                      id="gender-input"
                      name="gender"
                      label="Gender"
                      placeholder="Select gender"
                      options={genderOptions}
                      onChange={(val) => setSelectedGender(val)}
                      values={selectedGender}
                      disabled={isSaving}
                      labelField="label"
                      valueField="value"
                    />
                    <Controller
                      name="birthday"
                      control={control}
                      defaultValue={get(profileData, 'birthday') || null}
                      render={({ field }) => (
                        <DatePicker
                          id="date-input"
                          name="birthday"
                          label="Birthday"
                          placeholder="aaaa/mm/dd"
                          wide
                          disabled={isSaving}
                          handleChange={(date) => field.onChange(date)}
                          value={field.value ? new Date(field.value) : null}
                        />
                      )}
                    />
                  </Styled.DualInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={get(profileData, 'address') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="address"
                          label="Address"
                          placeholder="e.g. London"
                          wide
                          disabled={isSaving}
                          error={errors.address?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="city"
                      control={control}
                      defaultValue={get(profileData, 'city') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="city"
                          label="City"
                          placeholder="e.g. London"
                          wide
                          disabled={isSaving}
                          error={errors.city?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Selector
                      id="residence-country-input"
                      name="country_of_residence"
                      label="Country of residence"
                      placeholder="Select country of residence"
                      options={countriesOptions}
                      onChange={(val) => {
                        setValue('dirtyForm', 'true', { shouldDirty: true });
                        setCountryOfResidence(val);
                      }}
                      values={countryOfResidence}
                      labelField="label"
                      valueField="value"
                      searchable
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="pin"
                      control={control}
                      defaultValue={get(profileData, 'pin') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="pin"
                          label="Personal ID card number"
                          placeholder="e.g. X1234567890"
                          wide
                          disabled={isSaving}
                          error={errors.pin?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Subtitle text="Contacts" />
                  <Styled.SingleInputRow>
                    <Controller
                      name="address"
                      control={control}
                      defaultValue={get(profileData, 'address') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="address"
                          label="Residence address"
                          placeholder="e.g. Baker street 1, London"
                          wide
                          disabled={isSaving}
                          error={errors.address?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="email"
                      control={control}
                      defaultValue={get(profileData, 'email') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="email"
                          label="Email"
                          placeholder="e.g. yourname@gmail.com"
                          wide
                          disabled={isSaving}
                          error={errors.email?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Styled.SingleInputRow>
                    <Controller
                      name="phone"
                      control={control}
                      defaultValue={get(profileData, 'phone') || ''}
                      render={({ field }) => (
                        <TextInput
                          id="phone"
                          type="phone"
                          label="Phone"
                          placeholder="e.g. +1 123 45 67 890"
                          wide
                          disabled={isSaving}
                          error={errors.phone?.message}
                          {...field}
                        />
                      )}
                    />
                  </Styled.SingleInputRow>
                  <Subtitle text="Bank account" />
                  <Styled.BankAccount>
                    {bankAccounts.map((b) => (
                      <Styled.SingleInputRow key={b.id}>
                        <TextInput
                          label={b.account_name}
                          value={b.account_number}
                          onBtnClick={() => {
                            setBankAccountToEdit(b);
                            setBankFormDisplay(true);
                          }}
                          button={<Styled.BankAccountBtn><IconEdit /></Styled.BankAccountBtn>}
                          disabled={isSaving}
                        />
                      </Styled.SingleInputRow>
                    ))}
                    <Styled.BtnWrapper>
                      <Button
                        variant="outlined"
                        size="small"
                        wide
                        handleClick={() => setBankFormDisplay(true)}
                        disabled={isSaving}
                      >
                        Add bank account
                      </Button>
                    </Styled.BtnWrapper>
                  </Styled.BankAccount>
                  <Subtitle text="Files" />
                  {superAdmin && (
                    <Styled.UserFiles>
                      <Files files={files} />
                    </Styled.UserFiles>
                  )}
                  <Subtitle text="Surveys" />
                  {surveysLoading ? <Loader color="#616E7F" /> : <Surveys data={surveys} />}
                  <Styled.BtnWrapper>
                    <Button
                      type="submit"
                      wide
                      handleClick={() => { }}
                      disabled={isSaving}
                    >
                      Save
                    </Button>
                    <Button
                      variant="outlined"
                      type="button"
                      wide
                      handleClick={() => history.push('/users')}
                      disabled={isSaving}
                    >
                      Cancel
                    </Button>
                  </Styled.BtnWrapper>
                </Styled.Form>
                <Styled.Sidebar>
                  <YocCircles
                    userID={userID}
                    userClickCb={(u) => {
                      dispatch(appendToNavigationHistory(u));
                      history.push(`/users/${u.user_id}`);
                    }}
                    title="User's circles"
                  />
                  <Styled.UserRoles>
                    <Subtitle text="User roles" />
                    <Styled.UserRolesList>
                      {roles.map(role => (
                        <Styled.UserRolessItem key={role.id}>
                          <span>{role.label}</span>
                          <Styled.RoleDeleteBtn
                            disabled={roleChanging}
                            onClick={() => handleRoleDelete(role)}
                          >
                            <IconDelete />
                          </Styled.RoleDeleteBtn>
                        </Styled.UserRolessItem>
                      ))}
                      <Styled.UserRolesBtn>
                        <Button
                          variant="text"
                          wide
                          onClick={() => setAddRoleDisplay(true)}
                          disabled={roles.length === clientRoleOptions.length || roleChanging}
                        >
                          Add role
                        </Button>
                      </Styled.UserRolesBtn>
                    </Styled.UserRolesList>
                  </Styled.UserRoles>
                  {featured.length ? (
                    <Button size="small" handleClick={handleFeaturedDelete} variant="warning">
                      Remove from featured
                    </Button>
                  ) : (
                    <Button size="small" handleClick={handleFeatured}>
                      Set as featured
                    </Button>
                  )}
                  <Button size="small" handleClick={() => setChangeImageDisplayed(true)} variant="outlined">
                    Change profile picture
                  </Button>
                </Styled.Sidebar>
              </Styled.MainSection>
            </Styled.ClientPageMain>
          </Styled.ClientPageMainWrapper>
        </Styled.ClientPage>
        {showBankForm && (
          <BankAccountForm
            isOpen={showBankForm}
            closeCb={() => {
              setBankFormDisplay(false);
              setBankAccountToEdit(null);
            }}
            refreshUser={getUser}
            user={profileData}
            bankAccount={bankAccountToEdit}
          />
        )}
        {isAddRoleDisplayed && (
          <AddRoleForm
            isOpen={isAddRoleDisplayed}
            closeCb={() => setAddRoleDisplay(false)}
            confirmCb={() => {
              getRoles();
              setAddRoleDisplay(false);
            }}
            remainingRoles={remainingRoles}
            userID={userID}
          />
        )}
        {isChangeImageDisplayed && (
          <ChangeProfilePictureForm
            isOpen={isChangeImageDisplayed}
            closeCb={() => setChangeImageDisplayed(false)}
            confirmCb={() => {
              getUser();
              setChangeImageDisplayed(false);
            }}
            user={profileData}
          />
        )}
        <NavigationHistory />
      </Styled.ClientPageWrapper>
    </Page>
  );
};

export default ClientPage;
