import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { Table, StatusCell, UserInfoCell } from 'shared/components';
import { appendToNavigationHistory } from 'redux/navigation/actions';
import * as Styled from './styles';

const ClientsList = ({
  data,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'full_name',
      Cell: cell => (
        <UserInfoCell
          profileImg={get(cell, 'row.original.profile_picture')}
          name={get(cell, 'value') || ''}
          metaFirst={get(cell, 'row.original.user_name') || ''}
        />
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: cell => {
        const { value } = cell;
        if (value === 'Pending') { return `${value}...`; }
        return <StatusCell value={value} />;
      },
    },
  ], []);

  const handleRowClick = (val) => {
    dispatch(appendToNavigationHistory(val));
    history.push(`/users/${val.user_id}`);
  };

  return (
    <Styled.ClientsList>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onFetchData={() => {}}
        onRowClick={handleRowClick}
      />
    </Styled.ClientsList>
  );
};

ClientsList.propTypes = {
  data: PropTypes.array,
};

ClientsList.defaultProps = {
  data: [],
};

export default ClientsList;
