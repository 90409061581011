import styled from 'styled-components';

export const InviteAdminForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 12px 24px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 8px;
  }
`;

export const HeaderTitleText = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  font-weight: bold;
`;

export const HeaderClose = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 24px 12px 24px;
  justify-content: center;
  height: 100%;

  .Selector {
    max-width: 100%;

    .react-dropdown-select {
      max-width: 100%;
    }
  }

  .Button {
    margin-top: auto;
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;

  .TextInput {
    max-width: unset;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;
