import styled from 'styled-components';

export const NavigationHistory = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 230px;
  background: ${props => props.theme.colors.shades.neutral.white};
  z-index: 999;

  &.NavigationHistory-closed {
    bottom: unset;
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.theme.colors.shades.neutral.gray50};
    z-index: 98;
  }

  &.NavigationHistory-mobile {
    top: unset;
    width: 100%;
    bottom: 0;

    &.NavigationHistory-open {
      top: 0;
    }
  }
`;

export const Header = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
  margin-right: 20px;
`;

export const List = styled.ul`
  display: flex;
  flex-direction: column;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  padding: 8px 16px;
  list-style-type: none;
  max-height: 89vh;
  overflow: auto;
`;

export const ListItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;

  .IconNoProfilePicture20 {
    margin-right: 8px;
    z-index: 2;
    flex-shrink: 0;
  }
`;

export const ListItemText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const ListItemLine = styled.div`
  position: absolute;
  bottom: -16px;
  left: 9px;
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.colors.shades.warm.dawn40};
`;

export const ListFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-top-color: ${props => props.theme.colors.shades.warm.dawn20};
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};

  .Button {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
`;

export const BtnInner = styled.div`
  display: flex;
  align-items: center;

  .IconDelete {
    margin-right: 10px;
  }
`;

export const BtnText = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;
