import styled from 'styled-components';

export const MobileMenu = styled.div`
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  color: ${props => props.theme.colors.shades.neutral.white};
  font-weight: bold;
  line-height: 24px;
  text-transform: capitalize;
`;

export const TitleIcon = styled.div`
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ToggleBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
`;

export const AdminLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.neutral.white};
  margin-right: 20px;
`;

export const Menu = styled.div`
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  /* height: calc(100vh - 39px); */
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100 - 40px);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors.primary.daintree.daintree40};
  display: flex;
  flex-direction: column;
`;

export const Nav = styled.nav`
  margin-top: 0px;
`;

export const EntityDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 17px 13px 10px;
`;

export const EntityName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.neutral.white};
  margin-left: 8px;
`;

export const EntityLogo = styled.img`
  width: 100%;
  max-width: 32px;
  height: auto;
`;

export const NavItem = styled.div`
  height: 48px;

  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary.daintree.daintree05};
    text-decoration: none;
    padding: 13px 17px;
    height: 100%;
    position: relative;

    &.active {
      background-color: ${props => props.theme.colors.primary.daintree.daintree40};

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.shades.neutral.white};
        position: absolute;
        left: -4px;
      }
    }
  }
`;

export const NavItemText = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-left: 17px;
  position: relative;
`;

export const Footer = styled.div`
  margin-top: auto;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors.primary.daintree.daintree40};
  padding: 8px 0;
`;

export const FooterItem = styled.div`
  height: 48px;
`;

export const LogoutBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 13px 17px;
  height: 100%;
  width: 100%;
`;

export const LogoutBtnText = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-left: 17px;
  color: ${props => props.theme.colors.shades.neutral.white};
`;

export const UnreadCount = styled.span`
  position: absolute;
  margin-left: 2px;
  font-size: 11px;
  top: -5px;
  right: -24px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.danger.danger50};
  font-weight: bold;
`;

export const Version = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.shades.neutral.white};
  font-size: 12px;
  margin-left: 17px;
`;
