import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import { Button, Loader } from 'shared/components';
import { getFileExtension, dataURItoBlob } from 'shared/helpers';
import api from 'shared/api';
import { sendErrorReport } from 'shared/errorReport';
import { IconUser, IconClose, IconUpload } from 'shared/icons';
import noImagePlaceholder from 'shared/assets/placeholder-image.png';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const ChangeProfilePictureForm = ({
  isOpen,
  closeCb,
  confirmCb,
  user,
}) => {
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(get(user, 'profile_picture'));
  const [isProfilePictureLoading, setProfilePictureLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const onFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };
  const updateProfilePicture = useCallback(() => {
    setLoading(true);

    // define the width to resize e.g 300px
    const resizeWidth = 300; // without px

    // create a FileReader
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    // image turned to base64-encoded Data URI.
    reader.readAsDataURL(selectedFile);
    reader.name = selectedFile.name; // get the image's name
    reader.size = selectedFile.size; // get the image's size

    reader.onload = (event) => {
      // eslint-disable-next-line no-undef
      const img = new Image();// create a image
      img.src = event.target.result;// result is base64-encoded Data URI
      img.onload = (el) => {
        const elem = document.createElement('canvas');// create a canvas

        // scale the image to 300 (width) and keep aspect ratio
        const scaleFactor = resizeWidth / el.target.width;
        elem.width = resizeWidth;
        elem.height = el.target.height * scaleFactor;

        // draw in canvas
        const ctx = elem.getContext('2d');
        ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

        // get the base64-encoded Data URI from the resize image
        const srcEncoded = ctx.canvas.toDataURL('image/png', 1);
        const blob = dataURItoBlob(srcEncoded);

        const formData = new FormData();
        formData.append(
          'image',
          blob,
          get(selectedFile, 'name') || 'profile_picture',
        );
        formData.append('type', 'profile_picture');
        formData.append('extension', getFileExtension(get(selectedFile, 'name')));

        api.post(`/api/user-management/user-profiles/${userID}/files`, formData)
          .then(() => {
            setProfilePicture(URL.createObjectURL(selectedFile));
            setProfilePictureLoading(false);
            setLoading(false);
            setSelectedFile(null);
            confirmCb();
            toast.success('Profile picture uploaded');
          })
          .catch((err) => {
            sendErrorReport(err, 'change_profile_picture', formData);
            setLoading(false);
            setProfilePictureLoading(false);
            toast.error('Unable to upload image');
          });
      };
    };
  }, [userID, selectedFile]);

  const handleSubmit = () => {
    if (isLoading) {
      return false;
    }

    setLoading(true);
    updateProfilePicture();
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  const getContent = () => {
    if (isProfilePictureLoading) {
      return <Styled.UserProfilePictureUpload><Loader color="#A3A3A3" /></Styled.UserProfilePictureUpload>;
    }

    if (profilePicture) {
      return (
        <Styled.UserProfilePictureUpload>
          <Styled.PicturePreview>
            <Styled.PicturePreviewImg src={profilePicture} alt="profile_picture" />
          </Styled.PicturePreview>
          <div>
            <Styled.Label className="custom-file-upload" htmlFor="file-upload">
              <Styled.Input type="file" id="file-upload" accept="image/*" capture="camera" onChange={onFileChange} />
              <Styled.UploadData>
                {selectedFile ? (
                  <Styled.UploadPreviewWrapper>
                    <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
                    <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
                      Upload
                    </Button>
                  </Styled.UploadPreviewWrapper>
                ) : (
                  <Styled.ChangePicture>
                    <IconUpload height={14} width={14} />
                    Change picture
                  </Styled.ChangePicture>
                )}
              </Styled.UploadData>
            </Styled.Label>
          </div>
        </Styled.UserProfilePictureUpload>
      );
    }

    return (
      <Styled.UserProfilePictureUpload>
        <Styled.Picture src={noImagePlaceholder} alt="no picture placeholder" />
        <Styled.Label className="custom-file-upload" htmlFor="file-upload">
          <Styled.Input type="file" id="file-upload" onChange={onFileChange} />
          <Styled.Uploader>
            <Styled.Left>
              <Styled.UploaderLabel>Profile picture</Styled.UploaderLabel>
              {selectedFile ? (
                <Styled.UploadPreviewWrapper>
                  <Styled.UploadPreview src={URL.createObjectURL(selectedFile)} />
                </Styled.UploadPreviewWrapper>
              ) : (
                <Styled.Desc>Upload...</Styled.Desc>
              )}
            </Styled.Left>
            <Styled.Right><IconUpload /></Styled.Right>
          </Styled.Uploader>
        </Styled.Label>
        {selectedFile && (
          <Button wide size="small" onClick={handleSubmit} disabled={isLoading} isLoading={isLoading}>
            Upload
          </Button>
        )}
      </Styled.UserProfilePictureUpload>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="ChangeProfilePictureFormModal"
    >
      <Styled.ChangeProfilePictureForm>
        <Styled.Header>
          <IconUser />
          <Styled.HeaderTitle>Change profile picture</Styled.HeaderTitle>
        </Styled.Header>
        {getContent()}
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={handleClose}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.ChangeProfilePictureForm>
    </ReactModal>
  );
};

ChangeProfilePictureForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ChangeProfilePictureForm;
