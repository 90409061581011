import * as actionTypes from './actionTypes';

const initialState = {
  checkingUser: false,
  userLoading: true,
  user: {},
  userProfileLoading: true,
  userProfile: {},
};

// eslint-disable-next-line func-names
export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getUserInit:
      return { ...state, userLoading: true };
    case actionTypes.getUserSuccess:
      return {
        ...state,
        userLoading: false,
        user: action.user,
        error: undefined,
      };
    case actionTypes.getUserError:
      return { ...state, userLoading: false };
    case actionTypes.refreshUser:
      return {
        ...state,
        user: action.payload,
      };
    case actionTypes.getUserProfileInit:
      return { ...state, userProfileLoading: true };
    case actionTypes.getUserProfileSuccess:
      return {
        ...state,
        userProfileLoading: false,
        userProfile: action.payload.user,
      };
    case actionTypes.refreshUserProfile:
      return {
        ...state,
        userProfile: action.payload,
      };
    case actionTypes.getUserProfileError:
      return { ...state, userProfileLoading: false };
    case actionTypes.loginInit:
      return { ...state, checkingUser: true };
    case actionTypes.loginSuccess:
      return {
        ...state,
        checkingUser: false,
        user: action.user,
        error: undefined,
      };
    case actionTypes.loginError:
      return { ...state, error: action.payload, checkingUser: false };
    case actionTypes.invitationSuccess:
      return {
        ...state,
        user: action.user,
      };
    case actionTypes.invitationError:
      return { ...state, registerLoading: false };
    case actionTypes.logoutSuccess: {
      return { ...initialState, userLoading: false, error: undefined };
    }
    case actionTypes.logoutError: {
      return { ...state, error: action.payload };
    }
    default:
      return { ...state };
  }
}
