import { Route, Switch } from 'react-router-dom';
import OrganizationsList from './OrganizationsList';
import OrganizationPage from './OrganizationPage';

const Organizations = () => (
  <Switch>
    <Route exact path="/organizations" component={OrganizationsList} />
    <Route exact path="/organizations/:organizationID" component={OrganizationPage} />
  </Switch>
);

export default Organizations;
