import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
import NumberFormat from 'react-number-format';
import 'react-phone-number-input/style.css';
import * as Styled from './styles';

const TextInput = React.forwardRef(({
  button,
  disabled,
  error,
  showError,
  helperText,
  id,
  isRequired,
  label,
  leadingIcon,
  onBtnClick,
  onChange,
  placeholder,
  trailingIcon,
  type,
  value,
  size,
  wide,
  prefix,
  ...rest
}, ref) => {
  const getInput = () => {
    if (type === 'phone') {
      return (
        <Styled.PhoneInput className="phone-input">
          <PhoneInput
            id={id}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            required={isRequired}
            type={type}
            ref={ref}
            {...rest}
          />
        </Styled.PhoneInput>
      );
    }

    if (type === 'number') {
      return (
        <Styled.NumberInput>
          <NumberFormat
            thousandSeparator
            prefix={prefix}
            value={value}
            id={id}
            placeholder={placeholder}
            disabled={disabled}
            required={isRequired}
            ref={ref}
            onValueChange={values => onChange(values?.value)}
          />
        </Styled.NumberInput>
      );
    }

    return (
      <Styled.Input
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        required={isRequired}
        type={type}
        ref={ref}
        {...rest}
      />
    );
  };

  return (
    <>
      <Styled.TextInput disabled={disabled} hasError={error} size={size} wide={wide} className="TextInput">
        <>{leadingIcon ? <Styled.IconLeading>{leadingIcon}</Styled.IconLeading> : null}</>
        <Styled.TextInputMain>
          {label && (
            <Styled.LabelContainer>
              <Styled.Label className="Label" htmlFor={id}>{label}</Styled.Label>
              {isRequired && <Styled.LabelRequired className="LabelRequired">(Required)</Styled.LabelRequired>}
            </Styled.LabelContainer>
          )}
          {getInput()}
        </Styled.TextInputMain>
        <>{(trailingIcon && !button) ? <Styled.IconTrailing>{trailingIcon}</Styled.IconTrailing> : null}</>
        <>{button ? <Styled.InputButton type="button" onClick={onBtnClick}>{button}</Styled.InputButton> : null}</>
      </Styled.TextInput>
      <>{helperText && <Styled.HelperText>{helperText}</Styled.HelperText>}</>
      <>{(error && showError) && <Styled.ErrorText>{error}</Styled.ErrorText>}</>
    </>
  );
});

TextInput.propTypes = {
  button: PropTypes.element,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  wide: PropTypes.bool,
  showError: PropTypes.bool,
  label: PropTypes.string,
  leadingIcon: PropTypes.element,
  onBtnClick: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  trailingIcon: PropTypes.element,
  type: PropTypes.string,
  prefix: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

TextInput.defaultProps = {
  button: null,
  disabled: false,
  wide: false,
  showError: true,
  error: '',
  helperText: '',
  isRequired: false,
  label: '',
  leadingIcon: null,
  onBtnClick: () => { },
  onChange: () => { },
  placeholder: '',
  trailingIcon: null,
  type: 'text',
  size: 'large',
  value: '',
  prefix: '',
};

export default TextInput;
