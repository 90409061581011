import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  TextInput,
  SidePanel,
  Selector,
} from 'shared/components';
import { clientRoleOptions } from 'shared/constants';
import {
  IconClose,
  IconUserAdd,
} from 'shared/icons';
import api from 'shared/api';
import * as Styled from './styles';

const validationSchema = yup.object().shape({
  invitee_full_name: yup
    .string()
    .required(),
  email: yup
    .string()
    .email()
    .required(),
});

const InviteClientForm = ({
  isOpen,
  closeCb,
}) => {
  const currentUser = useSelector(state => get(state, 'account.user'));
  const userName = get(currentUser, 'user_name');

  const [isLoading, setLoading] = useState(false);
  const [roleNames, setRoleNames] = useState([clientRoleOptions[0]]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invitee_full_name: '',
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });

  const isRoleSelected = () => {
    const hasLength = roleNames.length;
    if (!hasLength) {
      toast.error('Role is a required field');
      return false;
    }
    return true;
  };

  const getRoleNames = () => roleNames.map(r => r.value);

  const onSubmit = (data, e) => {
    e.preventDefault();
    if (isLoading || !isRoleSelected()) {
      return false;
    }

    setLoading(true);
    const formData = {
      inviter: userName,
      invitee: data.email,
      role_names: getRoleNames(),
      topo_type: null,
      app_name: 'client',
      invitee_full_name: get(data, 'invitee_full_name.value') || get(data, 'invitee_full_name'),
    };

    api.post('/api/yoc/invitations', formData)
      .then(() => {
        setLoading(false);
        toast.success(`Invitation sent to ${formData.invitee_full_name}`);
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <SidePanel isOpen={isOpen} close={closeCb}>
      <Styled.InviteClientForm>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconUserAdd />
            <Styled.HeaderTitleText>Invite user</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={closeCb}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.Form onSubmit={handleSubmit(onSubmit)}>
          <Styled.DetailsRow>
            <Styled.InputContainer>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextInput
                    id="email-input"
                    label="Email"
                    placeholder="Enter email"
                    type="email"
                    error={errors.email?.message}
                    {...field}
                  />
                )}
              />
            </Styled.InputContainer>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Controller
              name="invitee_full_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextInput
                  id="invitee_full_name"
                  label="Full name"
                  placeholder="e.g. John Smith"
                  wide
                  disabled={isLoading}
                  error={errors.invitee_full_name?.message}
                  {...field}
                />
              )}
            />
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Selector
              id="role_name-input"
              name="role_name"
              label="Sugested roles"
              placeholder="Select role name"
              options={clientRoleOptions}
              onChange={(val) => setRoleNames(val)}
              values={roleNames}
              labelField="label"
              valueField="value"
              multi
            />
          </Styled.DetailsRow>
          <Button
            size="large"
            type="submit"
            isLoading={isLoading}
            loaderColor="#fff"
          >
            Submit
          </Button>
        </Styled.Form>
      </Styled.InviteClientForm>
    </SidePanel>
  );
};

InviteClientForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

export default InviteClientForm;
