import styled from 'styled-components';

export const UserCirclesModal = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  position: relative;
  width: 100%;
  min-height: 368px;
  max-height: 368px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 24px 14px;
  /* min-height: 60px; */

  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .Button {
    margin: 0;
    margin-right: 10px;
    padding: 4px 4px;

    svg {
      margin-right: 0;
    }
  }
`;

export const HeaderTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 5px;
  right: 10px;

  button {
    padding: 0;
    margin: 0;

    &:hover {
      background: transparent;
    }
  }
`;

export const Main = styled.div`
  overflow: auto;
  max-height: 320px;

  .Loader {
    margin: 100px 0;
  }
`;

export const TopologyItem = styled.button`
  appearance: none;
  cursor: pointer;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors.shades.warm.dawn20};
  padding: 8px 24px;

  &:last-of-type {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};
  }

  &:hover {
    background: ${props => props.theme.colors.primary.daintree.daintree05};
  }
`;

export const TopologyItemTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const TopologyItemCount = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const UserItem = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};

  svg {
    margin-right: 12px;
  }
`;

export const UserStatus = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;
