import styled from 'styled-components';

export const ClientPageWrapper = styled.section`
  width: 100%;
  display: flex;
  margin-left: 40px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    /* justify-content: center; */
    margin-left: 0;
    max-width: 100%;
  }
`;

export const ClientPage = styled.div`
  width: 100%;
  max-width: 684px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 100%;
  }
`;

export const ClientPageHeader = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: ${props => props.theme.colors.shades.warm.dawn05};
  z-index: 999;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 12px 0px;
    display: none;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    display: none;
  }
`;

export const TitleIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const TitleText = styled.div`
  display: flex;
  align-items: center;
  color: #616E7F;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
`;

export const ClientPageMainWrapper = styled.div`
  padding-bottom: 20px;
`;

export const ClientPageMain = styled.div`
  background-color: ${props => props.theme.colors.shades.neutral.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  padding: 24px;
  margin-top: 4px;
  display: flex;
  flex-direction: column;

  .Loader {
    margin: 100px auto;
  }
`;

export const Logo = styled.div`
  width: 200px;
  margin-right: 16px;
  flex-shrink: 0;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    margin-bottom: 36px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  background: ${props => props.theme.colors.shades.warm.dawn40};
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn10};
`;

export const ProfileName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;

export const MainSection = styled.div`
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }

  .Loader {
    margin: 10px;
  }
`;

export const Form = styled.form`
  margin-right: 16px;
  width: 100%;
  max-width: 420px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    order: 2;
  }
`;

export const DualInputRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;

  .TextInput,
  .Selector,
  .DatePickerWrapper {
    max-width: 200px;
    min-width: 200px;
  }

  .DatePicker {
    max-width: 150px;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    margin-bottom: 0;

    .TextInput,
    .Selector,
    .DatePickerWrapper {
      max-width: unset;
      width: 100%;
      margin-bottom: 16px;
    }

   .DatePickerWrapper {
      margin-bottom: 0;
    }

    .react-dropdown-select {
      max-width: unset;
    }
  }
`;

export const SingleInputRow = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;

  .Selector {
    max-width: unset;

    .react-dropdown-select {
      max-width: unset;
    }
  }
`;

export const UserRoles = styled.div`
  margin-top: 16px;

  button {
    margin-top: 0;
  }
`;

export const UserRolesList = styled.ul`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  border-bottom: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style-type: none;
`;

export const UserRolessItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  padding: 9px 12px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};

  span {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const RoleDeleteBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: auto;
`;

export const UserRolesBtn = styled.div`
  appearance: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};

    .Button {
      margin: 0;
      padding: 5px 12px;
      padding-bottom: 6px;
      width: 100%;
      max-width: unset;
    }
`;

export const UserFiles = styled.div`
  margin-bottom: 16px;
`;

export const BankAccount = styled.div`
  margin-top: 16px;

  .TextInput {
    max-width: unset;
  }
`;

export const BankAccountBtn = styled.div`
  cursor: pointer;
  border: none;
  background: transparent;
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .Button {
    margin-top: 0;
    max-width: unset;

    &:first-of-type {
      margin-right: 20px;
    }
  }
`;

export const Sidebar = styled.div`
  width: 200px;

  .Button {
    width: 100%;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    order: 1;
    width: 100%;
    max-width: 420px;
    margin-bottom: 20px;
  }
`;
