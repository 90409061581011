import { useState } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Button, Checkbox } from 'shared/components';
import { IconFilter, IconClose } from 'shared/icons';
import api from 'shared/api';
import toast from 'react-hot-toast';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px',
  },
};

const AddRoleForm = ({
  isOpen,
  closeCb,
  confirmCb,
  remainingRoles,
  userID,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRolesError, setSelectedRolesError] = useState('');

  const handleRoleSelect = (role) => {
    setSelectedRolesError('');
    const isAdded = selectedRoles.find(ar => ar === role.value);
    if (isAdded) {
      const list = selectedRoles.filter(ar => ar !== role.value);
      setSelectedRoles(list);
    } else {
      const list = [...selectedRoles, role.value];
      setSelectedRoles(list);
    }
  };

  const handleSubmit = () => {
    if (!selectedRoles.length) {
      setSelectedRolesError('Select at least one role');
      return false;
    }
    setLoading(true);
    const data = {
      role_names: selectedRoles.map(r => r),
      app_name: 'client',
    };
    api.post(`/api/user-management/user-roles/${userID}/bulk`, data)
      .then(() => {
        confirmCb();
        toast.success('User roles updated');
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="AddRoleFormModal"
    >
      <Styled.AddRoleForm>
        <Styled.Header>
          <IconFilter />
          <Styled.HeaderTitle>Add role</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.Roles>
          {remainingRoles.map(r => (
            <Checkbox
              key={r.id}
              id={r.value}
              value={selectedRoles.includes(r.value)}
              label={r.label}
              onChange={() => handleRoleSelect(r)}
            />
          ))}
        </Styled.Roles>
        {selectedRolesError && <Styled.Error>{selectedRolesError}</Styled.Error>}
        <Button
          wide
          disabled={isLoading}
          onClick={handleSubmit}
        >
          Save
        </Button>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={() => closeCb()}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.AddRoleForm>
    </ReactModal>
  );
};

AddRoleForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  remainingRoles: PropTypes.array.isRequired,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AddRoleForm;
