import {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import {
  Button,
  PageLoader,
  Loader,
  Table,
  UserInfoCell,
} from 'shared/components';
// import { clientRoleOptions } from 'shared/constants';
import { IconDelete } from 'shared/icons';
import api from 'shared/api';
import AddDealroomMember from '../AddDealroomMember';
import * as Styled from './styles';

const DealRoomMembers = ({
  // eslint-disable-next-line no-unused-vars
  dealRoom,
  members,
  // eslint-disable-next-line no-unused-vars
  owners,
  loading,
  refetchMembers,
  isUserDealroomOwner,
}) => {
  const history = useHistory();
  const [memberDeleting, setMemberDeleting] = useState(null);
  const [isAddMemberDisplayed, setAddMemberDisplay] = useState(false);

  const handleMemberDelete = (member) => {
    const memberID = get(member, 'id');
    setMemberDeleting(memberID);
    api.delete(`/api/investing/deal-rooms/members/${memberID}`)
      .then(() => {
        refetchMembers();
        setMemberDeleting(null);
        toast.success('Dealroom member removed');
      })
      .catch(() => {
        setMemberDeleting(null);
        toast.error('Error occured');
      });
  };

  const tableData = useMemo(() => members, [members]);
  const tableColumns = useMemo(() => [
    {
      Header: 'User',
      accessor: 'profile.full_name',
      Cell: cell => (
        <UserInfoCell
          profileImg={get(cell, 'row.original.profile.profile_picture')}
          name={get(cell, 'value')}
          metaFirst={get(cell, 'row.original.profile.title')}
        />
      ),
      minWidth: 250,
    },
    // {
    //   Header: 'Role',
    //   id: 'role',
    //   Cell: (cell) => {
    //     const isMember = get(cell, 'row.original.member');
    //     if (isMember) {
    //       return 'Investor';
    //     }
    //     const ownerRole = get(cell, 'row.original.role') || [];
    //     const role = clientRoleOptions.find(cr => cr.value === get(ownerRole, '[0]'));
    //     return get(role, 'label') || 'Owner';
    //   },
    // },
    {
      Header: 'Delete',
      headerClassName: 'centered',
      id: 'delete',
      Cell: (cell) => {
        const member = get(cell, 'row.original.member');
        if (!member) {
          return <Styled.TableBtn>-</Styled.TableBtn>;
        }
        const isDeleting = memberDeleting === get(cell, 'cell.row.original.member.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleMemberDelete(member);
              }}
            >
              {isDeleting ? <Loader /> : <IconDelete color="#CC3429" />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 80,
      maxWidth: 80,
      show: isUserDealroomOwner,
    },
  ], [members, isUserDealroomOwner]);

  const handleRowClick = row => {
    history.push(`/users/${get(row, 'profile.user_id')}`);
  };

  if (loading) {
    return <Styled.DealRoomMembers><PageLoader /></Styled.DealRoomMembers>;
  }

  return (
    <Styled.DealRoomMembers>
      <Button
        size="small"
        handleClick={() => setAddMemberDisplay(true)}
      >
        Add member
      </Button>
      <Table
        columns={tableColumns}
        data={tableData}
        isClickable
        onRowClick={handleRowClick}
        rowClassName="UsersListRow"
      />
      {isAddMemberDisplayed && (
        <AddDealroomMember
          isOpen={isAddMemberDisplayed}
          closeCb={() => setAddMemberDisplay(false)}
          refetchMembers={refetchMembers}
          dealRoom={dealRoom}
        />
      )}
    </Styled.DealRoomMembers>
  );
};

DealRoomMembers.propTypes = {
  dealRoom: PropTypes.object.isRequired,
  members: PropTypes.array.isRequired,
  owners: PropTypes.array.isRequired,
  refetchMembers: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isUserDealroomOwner: PropTypes.bool,
};

DealRoomMembers.defaultProps = {
  isUserDealroomOwner: false,
};

export default DealRoomMembers;
