import {
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import api from 'shared/api';
import {
  AddEntityBtn,
  Page,
  PageLoader,
  Pagination,
  TextInput,
} from 'shared/components';
import {
  IconOrganization,
  IconSearch,
  IconPlus,
} from 'shared/icons';
import { useWindowDimensions } from 'shared/HOCs';
import { OrganizationCard, OrganizationForm } from '../components';
import * as Styled from './styles';

const OrganizationsList = () => {
  const history = useHistory();
  const { isMobile } = useWindowDimensions();

  const [isLoading, setLoading] = useState(true);
  const [activeSearch, setActiveSearch] = useState('');
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [organizations, setOrganizations] = useState([]);
  const [isFormDisplayed, setFormDisplay] = useState(false);

  const {
    control,
    handleSubmit,
  } = useForm();

  const getOrganizations = useCallback((
    searchQuery = undefined,
    page = 0,
  ) => {
    const searchParam = searchQuery ? `&name=${searchQuery}` : '';
    api.get(`/api/user-management/business-entities?page=${page + 1}${searchParam}&per_page=10`)
      .then((res) => {
        setPageCount(get(res, 'data.count_pages'));
        setActivePage(page);
        const list = get(res, 'data.records');
        setOrganizations(list);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getOrganizations();
  }, [getOrganizations]);

  const onSearchSubmit = (data) => {
    const { search } = data;
    setActiveSearch(search);
    getOrganizations(search, 0);
  };

  const changeListPage = (val) => {
    const { selected } = val;
    if (selected !== activePage) {
      setActivePage(selected);
      getOrganizations(activeSearch, selected);
    }
  };

  if (isLoading) {
    return (
      <Page type="organizations" title="Organizations"><PageLoader /></Page>
    );
  }

  return (
    <Page type="organizations" title="Organizations">
      <Styled.OrganizationsWrapper>
        <Styled.OrganizationsList>
          <Styled.ListHeader>
            <Styled.ListTitle>
              <Styled.TitleIcon><IconOrganization /></Styled.TitleIcon>
              <Styled.TitleText>Organizations</Styled.TitleText>
            </Styled.ListTitle>
            <Styled.ListSearch onSubmit={handleSubmit(onSearchSubmit)}>
              <Controller
                name="search"
                control={control}
                defaultValue={activeSearch}
                render={({ field }) => (
                  <TextInput
                    id="organizations-search"
                    name="search"
                    placeholder="Search by name"
                    size="small"
                    button={<IconSearch />}
                    onBtnClick={handleSubmit(onSearchSubmit)}
                    {...field}
                  />
                )}
              />
            </Styled.ListSearch>
            <Styled.ListAction>
              <Styled.AddBtn type="button" onClick={() => setFormDisplay(true)}>
                <IconPlus />
              </Styled.AddBtn>
            </Styled.ListAction>
          </Styled.ListHeader>
          <Styled.ListWrapper>
            {!organizations.length && (
              <AddEntityBtn
                text="Create first organization"
                onClick={() => setFormDisplay(true)}
              />
            )}
            {organizations.map(o => (
              <OrganizationCard
                key={o.id}
                onClick={() => history.push(`/organizations/${o.id}`)}
                organization={{
                  logo: get(o, 'logo'),
                  name: get(o, 'name'),
                  bio: get(o, 'bio'),
                }}
              />
            ))}
            <Styled.ListPagination>
              <Pagination
                page={activePage}
                pageCount={pageCount}
                handlePageClick={changeListPage}
                pageRangeDisplayed={isMobile ? 1 : 3}
              />
            </Styled.ListPagination>
          </Styled.ListWrapper>
        </Styled.OrganizationsList>
        {isFormDisplayed && (
          <OrganizationForm
            isOpen={isFormDisplayed}
            closeCb={() => setFormDisplay(false)}
            organization={null}
          />
        )}
      </Styled.OrganizationsWrapper>
    </Page>
  );
};

export default OrganizationsList;
