import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SidePanel } from 'shared/components';
import { IconClose, IconSupportFill } from 'shared/icons';
import {
  formatDate,
  formatDateDistance,
  capitalizeFirstLetter,
  getEntityTypeLabel,
} from 'shared/helpers';
import { defaultDateTimeFormat } from 'shared/constants';
import api from 'shared/api';
import {
  OrganizationNotification,
  InvitationNotification,
  OfferingMemberNotification,
  UserNotification,
} from '../components';
import * as Styled from './styles';

const NotificationDetails = ({
  isOpen,
  onClose,
  entities,
  notification,
  refreshNotifications,
}) => {
  const notificationID = get(notification, 'actor.id');
  const entityType = get(notification, 'object.entity_type');
  const entityID = get(notification, 'object.entity_id');
  const entityUUID = get(notification, 'object.entity_uuid');
  const isSeen = get(notification, 'actor.status') === 'viewed';

  const getInvitationDetails = () => {
    const status = get(notification, 'object.status');
    const isApproved = status === 'approved';
    const entity = entities.find(e => get(e, 'Invitation.id') === entityUUID);
    const inviter = get(entity, 'Invitation.inviter_full_name') || '';
    const invitee = get(entity, 'Invitation.invitee_full_name') || '';
    const actor = isApproved ? invitee : inviter;
    const expiresAt = get(entity, 'Invitation.expires_at') || '';
    return actor || `Expires at ${formatDate(expiresAt)}`;
  };

  const getOrganizationName = () => {
    const entity = entities.find(e => get(e, 'BusinessEntity.id') === entityID);
    const name = get(entity, 'BusinessEntity.name') || '';
    return name;
  };

  const getOfferingMemberDetails = () => {
    const entity = entities.find(e => get(e, 'OfferingMember.member.id') === entityID);
    const name = get(entity, 'OfferingMember.profile.full_name') || '';
    return name;
  };

  const getUserDetails = () => {
    const entity = entities.find(e => get(e, 'User.id') === entityID);
    const name = get(entity, 'User.user_name') || '';
    return name;
  };

  const getDetails = () => {
    if (entityType === 'business_entity') {
      return getOrganizationName();
    }
    if (entityType === 'invitation') {
      return getInvitationDetails();
    }
    if (entityType === 'offering_member') {
      return getOfferingMemberDetails();
    }
    if (entityType === 'user') {
      return getUserDetails();
    }
    return entityType;
  };

  const setNotificationAsSeen = useCallback(() => {
    const formData = { status: 'viewed' };
    api.post(`/api/notifying/notifications/actors/${notificationID}/status`, formData)
      .then(() => {
        refreshNotifications();
      })
      .catch(() => {});
  }, [notificationID]);

  useEffect(() => {
    if (!isSeen) {
      setNotificationAsSeen();
    }
  }, [setNotificationAsSeen]);

  const getContent = () => {
    if (entityType === 'business_entity') {
      const entity = entities.find(e => get(e, 'BusinessEntity.id') === entityID);
      return (
        <OrganizationNotification organization={entity} notification={notification} />
      );
    }
    if (entityType === 'invitation') {
      const entity = entities.find(e => get(e, 'Invitation.id') === entityUUID);
      return <InvitationNotification closeCb={onClose} invitation={entity} notification={notification} />;
    }
    if (entityType === 'offering_member') {
      const entity = entities.find(e => get(e, 'OfferingMember.member.id') === entityID);
      return <OfferingMemberNotification closeCb={onClose} offeringMember={entity} notification={notification} />;
    }
    if (entityType === 'user') {
      const entity = entities.find(e => get(e, 'User.id') === entityID);
      return <UserNotification closeCb={onClose} user={entity} notification={notification} />;
    }
    return capitalizeFirstLetter(entityType);
  };

  return (
    <SidePanel isOpen={isOpen} close={onClose}>
      <Styled.NotificationDetails>
        <Styled.Header>
          <Styled.HeaderTitle>
            <IconSupportFill />
            <Styled.HeaderTitleText>{`${getEntityTypeLabel(get(notification, 'object.entity_type'))} ${get(notification, 'object.status')}`}</Styled.HeaderTitleText>
          </Styled.HeaderTitle>
          <Styled.HeaderClose type="button" onClick={onClose}>
            <IconClose height={12} width={12} color="#012934" />
          </Styled.HeaderClose>
        </Styled.Header>
        <Styled.DetailsInner>
          <Styled.DetailsRow>
            <Styled.RowLabel>Created</Styled.RowLabel>
            <Styled.RowValue>{formatDateDistance(get(notification, 'object.created_at'), defaultDateTimeFormat)}</Styled.RowValue>
          </Styled.DetailsRow>
          <Styled.DetailsRow>
            <Styled.RowLabel>Details</Styled.RowLabel>
            <Styled.RowValue>{getDetails()}</Styled.RowValue>
          </Styled.DetailsRow>
        </Styled.DetailsInner>
        <Styled.MessageContainer>
          {getContent()}
        </Styled.MessageContainer>
      </Styled.NotificationDetails>
    </SidePanel>
  );
};

NotificationDetails.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refreshNotifications: PropTypes.func.isRequired,
  entities: PropTypes.array.isRequired,
  notification: PropTypes.object.isRequired,
};

export default NotificationDetails;
