import styled from 'styled-components';

export const AddEntityBtn = styled.button`
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
  width: 100%;
  min-height: 80px;
  margin-top: 16px;
`;

export const Icon = styled.div`
  margin-right: 14px;
`;

export const Text = styled.div`
  line-height: 24px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
`;
