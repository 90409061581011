import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Subtitle, Loader } from 'shared/components';
import { capitalizeFirstLetter } from 'shared/helpers';
import UserCirclesModal from '../UserCirclesModal';
import * as Styled from './styles';

const UserCircles = ({
  data,
  dataCount,
  dataLoading,
  userID,
  title,
  userClickCb,
}) => {
  const [isCirclesModalDisplayed, setCirclesModalDisplay] = useState(false);

  if (dataLoading) {
    return (
      <Styled.UserCircles>
        {title ? (
          <Styled.Header>
            <Subtitle text={title} />
            <Styled.Count>{dataCount}</Styled.Count>
          </Styled.Header>
        ) : null}
        <Styled.CirclesWrapper loading>
          <Loader color="#616E7F" width={20} height={20} />
        </Styled.CirclesWrapper>
        <Styled.CirclesItemBtn>
          <Button
            variant="outlined"
            wide
            onClick={() => setCirclesModalDisplay(true)}
            disabled
          >
            See more
          </Button>
        </Styled.CirclesItemBtn>
      </Styled.UserCircles>
    );
  }

  return (
    <Styled.UserCircles>
      {title ? (
        <Styled.Header>
          <Subtitle text={title} />
          <Styled.Count>{dataCount}</Styled.Count>
        </Styled.Header>
      ) : null}
      <Styled.CirclesWrapper>
        {data.map((c, i) => {
          if (i < 3) {
            return (
              <Styled.CirclesItem key={c.name}>
                <Styled.CircleName>{capitalizeFirstLetter(c.name)}</Styled.CircleName>
                <Styled.CircleCount>{c.count}</Styled.CircleCount>
              </Styled.CirclesItem>
            );
          }
          return null;
        })}
        <Styled.CirclesItemBtn>
          <Button
            variant="text"
            wide
            onClick={() => setCirclesModalDisplay(true)}
            disabled={!dataCount}
          >
            See more
          </Button>
        </Styled.CirclesItemBtn>
      </Styled.CirclesWrapper>
      {isCirclesModalDisplayed && (
        <UserCirclesModal
          isOpen={isCirclesModalDisplayed}
          closeCb={() => setCirclesModalDisplay(false)}
          data={data}
          dataCount={dataCount}
          userID={userID}
          userClickCb={userClickCb}
        />
      )}
    </Styled.UserCircles>
  );
};

UserCircles.propTypes = {
  data: PropTypes.array,
  dataCount: PropTypes.number,
  dataLoading: PropTypes.bool,
  title: PropTypes.string,
  userID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  userClickCb: PropTypes.func,
};

UserCircles.defaultProps = {
  data: [],
  dataCount: 0,
  dataLoading: false,
  title: '',
  userClickCb: () => { },
};

export default UserCircles;
