import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ReactModal from 'react-modal';
import toast from 'react-hot-toast';
import {
  Button,
  FileUploader,
  Selector,
} from 'shared/components';
import { IconOffering, IconClose } from 'shared/icons';
import { getFileExtension, getUserRoles } from 'shared/helpers';
import api from 'shared/api';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
    width: '100%',
    height: '100%',
  },
};

const OfferingFilesUpload = ({
  isOpen,
  closeCb,
  offering,
  refetchFiles,
}) => {
  const offeringID = get(offering, 'id');
  const user = useSelector(state => get(state, 'account.user'));
  const userRoles = getUserRoles(user, false);
  const filteredUserRoles = userRoles.filter(ur => ur.value !== 'maker');

  const [isLoading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedRole, setSelectedRole] = useState([get(filteredUserRoles, '[0]')]);

  const uploadFiles = useCallback((data) => {
    const formData = new FormData();
    formData.append(
      'content',
      data.file,
      get(data, 'file.name') || 'file',
    );
    formData.append('name', get(data, 'file.name'));
    formData.append('type', 'offering_document');
    formData.append('user_role_id', get(selectedRole, '[0].id'));
    formData.append('extension', getFileExtension(get(data, 'file.name')));
    return api.post(`/api/investing/offerings/${offeringID}/files`, formData);
  }, [offeringID]);

  const submitAllFiles = () => Promise.all(files.map(uploadFiles));

  const handleSubmit = () => {
    if (!files.length) {
      toast.error('KYB form files have not been added');
      return false;
    }
    setLoading(true);
    submitAllFiles()
      .then(() => {
        refetchFiles();
        closeCb();
      })
      .catch(() => {
        toast.error('Error occured');
        setLoading(false);
      });
    return true;
  };

  const handleClose = () => {
    closeCb();
  };

  const hasMultipleRoles = filteredUserRoles.length > 1;

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="OfferingFilesUploadModal"
    >
      <Styled.OfferingFilesUpload>
        <Styled.Main>
          <Styled.Header>
            <IconOffering color="#20201E" />
            <Styled.HeaderTitle>Offering files upload</Styled.HeaderTitle>
          </Styled.Header>
          {hasMultipleRoles && (
            <Styled.InputContainer>
              <Selector
                id="role-input"
                name="selected-role"
                label="Your role"
                options={filteredUserRoles}
                onChange={(val) => {
                  setSelectedRole(val);
                }}
                values={selectedRole}
                labelField="label"
                valueField="value"
              />
            </Styled.InputContainer>
          )}
          <Styled.FileUploaderLabel>
            Upload shareholder list and financial information documents
          </Styled.FileUploaderLabel>
          <Styled.InputContainer>
            <FileUploader
              maxFiles={20}
              onUploadSuccess={(meta, allFiles) => {
                setFiles(allFiles);
              }}
              onItemRemove={(meta) => {
                const newFiles = files.filter(f => f.meta.id !== meta.id);
                setFiles(newFiles);
              }}
              validFileTypes=".pdf,.doc,.docx,.xls,.xlsx"
              disabled={isLoading}
            />
          </Styled.InputContainer>

          <Styled.ButtonContainer>
            <Button
              type="submit"
              wide
              handleClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </Button>
          </Styled.ButtonContainer>
        </Styled.Main>
      </Styled.OfferingFilesUpload>
      <Styled.CloseBtn>
        <Button
          variant="text"
          size="small"
          onClick={handleClose}
        >
          <IconClose color="#012934" width={12} height={12} />
        </Button>
      </Styled.CloseBtn>
    </ReactModal>
  );
};

OfferingFilesUpload.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchFiles: PropTypes.func.isRequired,
  offering: PropTypes.object.isRequired,
};

export default OfferingFilesUpload;
