import { useMemo, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { capitalizeFirstLetter } from 'shared/helpers';
import {
  Button,
  Loader,
  Section,
  Table,
} from 'shared/components';
import { IconDownload } from 'shared/icons';
import * as Styled from './styles';

const OrganizationFiles = ({
  files,
  loading,
}) => {
  const [fileDownloading, setFileDownloading] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const handleDocDownload = (file) => {
    if (fileDownloading) {
      return false;
    }
    const fileUrl = get(file, 'file.parts[0]');
    const fileID = get(file, 'file.id');
    const fileExtension = get(file, 'file.file_extension');
    const fileName = get(file, 'file.name');
    setFileDownloading(fileID);
    fetch(fileUrl, {
      method: 'GET',
      headers: {
        'Content-Type': `application/${fileExtension}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(
          // eslint-disable-next-line no-undef
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        const fallbackName = `business_entity_document-${fileID}`;
        link.setAttribute(
          'download',
          `${fileName || fallbackName}`,
        );
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setFileDownloading(null);
      })
      .catch(() => {
        setFileDownloading(null);
        toast.error('Error occured');
      });
    return true;
  };

  const tableData = useMemo(() => files, [files]);
  const tableColumns = useMemo(() => [
    {
      Header: 'Name',
      accessor: 'file.name',
      Cell: cell => capitalizeFirstLetter(cell.value, 'N/A'),
    },
    {
      Header: 'Download',
      headerClassName: 'centered',
      id: 'download',
      Cell: (cell) => {
        const isDownloading = fileDownloading === get(cell, 'cell.row.original.file.id');
        return (
          <Styled.TableBtn>
            <Button
              variant="text"
              size="small"
              onClick={() => handleDocDownload(get(cell, 'row.original'))}
            >
              {isDownloading ? <Loader /> : <IconDownload />}
            </Button>
          </Styled.TableBtn>
        );
      },
      width: 110,
      maxWidth: 110,
    },
  ], [files, fileDownloading]);

  return (
    <>
      <Section
        title="KYB submitted files"
        actions={null}
        content={(
          <Styled.OrganizationFiles>
            <>
              {loading ? <Loader /> : (
                <Table
                  columns={tableColumns}
                  data={tableData}
                  isClickable={false}
                  rowClassName="OrganizationFilesListRow"
                />
              )}
            </>
          </Styled.OrganizationFiles>
        )}
      />
    </>
  );
};

OrganizationFiles.propTypes = {
  files: PropTypes.array,
  loading: PropTypes.bool,
};

OrganizationFiles.defaultProps = {
  files: [],
  loading: true,
};

export default OrganizationFiles;
