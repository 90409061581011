import PropTypes from 'prop-types';
import * as Styled from './styles';

const StatusCell = ({
  value,
}) => (
  <Styled.Cell status={value}>{value || '-'}</Styled.Cell>
);

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default StatusCell;
