import PropTypes from 'prop-types';
import { IconPlus } from 'shared/icons';
import * as Styled from './styles';

const AddEntityBtn = ({
  text,
  onClick,
}) => (
  <Styled.AddEntityBtn type="button" onClick={onClick}>
    <Styled.Icon>
      <IconPlus width={18} height={18} color="#012934" />
    </Styled.Icon>
    {text && <Styled.Text>{text}</Styled.Text>}
  </Styled.AddEntityBtn>
);

AddEntityBtn.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

AddEntityBtn.defaultProps = {
  text: '',
  onClick: () => {},
};

export default AddEntityBtn;
