import * as actionTypes from './actionTypes';

const refreshNotificationsList = list => ({
  type: actionTypes.refreshUnreadNotifications,
  list,
});

export const refreshUnreadNotifications = (list) => (dispatch) => {
  dispatch(refreshNotificationsList(list));
};

const refreshTicketsList = list => ({
  type: actionTypes.refreshUnreadTickets,
  list,
});

export const refreshUnreadTickets = (list) => (dispatch) => {
  dispatch(refreshTicketsList(list));
};
