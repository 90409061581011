import { useState } from 'react';
import {
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import CurrentOfferings from './CurrentOfferings';
import PastOfferings from './PastOfferings';
import { OfferingForm } from '../components';
import * as Styled from './styles';

const OfferingsList = () => {
  const [isFormDisplayed, setFormDisplay] = useState(false);

  return (
    <Page type="offerings" title="Offerings">
      <Styled.OfferingsWrapper>
        <Styled.OfferingsList>
          <Tabs>
            <TabsHeader>
              <Tab>Current offerings</Tab>
              <Tab>Past offerings</Tab>
            </TabsHeader>
            <TabContent>
              <CurrentOfferings onAddBtnClick={() => setFormDisplay(true)} />
            </TabContent>
            <TabContent>
              <PastOfferings onAddBtnClick={() => setFormDisplay(true)} />
            </TabContent>
          </Tabs>
        </Styled.OfferingsList>
        {isFormDisplayed && (
          <OfferingForm
            isOpen={isFormDisplayed}
            closeCb={() => setFormDisplay(false)}
            offering={null}
          />
        )}
      </Styled.OfferingsWrapper>
    </Page>
  );
};

export default OfferingsList;
