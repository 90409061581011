import { useEffect, useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from 'Dashboard';
import Users from 'Users';
import UserPage from 'Users/UserPage';
import Profile from 'Profile';
import Support from 'Support';
import Organizations from 'Organizations';
import Offerings from 'Offerings';
import Notifications from 'Notifications';
import { NotFound, PageLoader } from 'shared/components';
import { useInterval } from 'shared/hooks';
import { getTopologyTypes } from 'redux/topology/actions';
import { refreshUnreadNotifications, refreshUnreadTickets } from 'redux/notifications/actions';
import { getCountries, getCurrencies } from 'redux/investing/actions';
import { getUserProfile } from 'redux/account/actions';
import api from 'shared/api';

const AppContainer = () => {
  const dispatch = useDispatch();

  const countriesLoading = useSelector(state => get(state, 'investing.countriesLoading'));
  const currenciesLoading = useSelector(state => get(state, 'investing.currenciesLoading'));
  const userProfileLoading = useSelector(state => get(state, 'account.userProfileLoading'));
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const getNotifications = useCallback(() => {
    api.get(`/api/notifying/users/${userID}/notifications?page=1&per_page=10000&status=pending`)
      .then((res) => {
        dispatch(refreshUnreadNotifications(get(res, 'data.objects.records')));
      });
  }, [userID]);

  const getTickets = useCallback(() => {
    api.get('/api/user-management/tickets?page=1&per_page=10000&seen=false')
      .then((res) => {
        dispatch(refreshUnreadTickets(get(res, 'data.records')));
      });
  }, [userID]);

  useEffect(() => {
    dispatch(getTopologyTypes());
    dispatch(getCurrencies());
    dispatch(getCountries());
    dispatch(getUserProfile(userID));
  }, [dispatch]);

  useEffect(getNotifications, [getNotifications]);
  useInterval(getNotifications, 7000);

  useEffect(getTickets, [getTickets]);
  useInterval(getTickets, 7000);

  if (countriesLoading || currenciesLoading || userProfileLoading) {
    return <PageLoader />;
  }

  return (
    <div className="AppContainer">
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/users/:userID" component={UserPage} />
        <Route path="/organizations" component={Organizations} />
        <Route path="/offerings" component={Offerings} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/notifications" component={Notifications} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AppContainer;
