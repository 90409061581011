/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { get } from 'lodash';
import { ImageGroup, Image } from 'react-fullscreen-image';
import { Button } from 'shared/components';
import { IconAttachment, IconClose } from 'shared/icons';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    // padding: '5% 0',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(41, 41, 41, 0.5)',
    zIndex: '999',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '600px',
  },
};

const FilesPreview = ({
  isOpen,
  closeCb,
  files,
}) => {
  let images = [];
  files.forEach((f) => {
    const list = get(f, 'file.parts') || [];
    images = images.concat(list);
  });

  return (
    <ReactModal
      isOpen={isOpen}
      style={styles}
      onRequestClose={() => {}}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="FilesPreviewModal"
    >
      <Styled.FilesPreview>
        <Styled.Header>
          <IconAttachment />
          <Styled.HeaderTitle>{`${images.length} Attachments`}</Styled.HeaderTitle>
        </Styled.Header>
        <Styled.ImagesContainer>
          <ImageGroup>
            <ul className="images">
              {images.map(i => (
                <li key={i}>
                  <Image
                    src={i}
                    alt="kyc image"
                  />
                </li>
              ))}
            </ul>
          </ImageGroup>
        </Styled.ImagesContainer>
        <Styled.CloseBtn>
          <Button
            variant="text"
            size="small"
            onClick={closeCb}
          >
            <IconClose color="#A19E95" width={12} height={12} />
          </Button>
        </Styled.CloseBtn>
      </Styled.FilesPreview>
    </ReactModal>
  );
};

FilesPreview.propTypes = {
  files: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  closeCb: PropTypes.func.isRequired,
};

FilesPreview.defaultProps = {
  files: [],
};

export default FilesPreview;
