import { useState } from 'react';
import PropTypes from 'prop-types';
import { IconAttachment } from 'shared/icons';
import FilesPreview from '../FilesPreview';
import * as Styled from './styles';

const Files = ({
  files,
}) => {
  const [isPreviewDisplayed, setPreviewDisplay] = useState(false);
  const selfiesNum = files.reduce((acc, f) => {
    if (f.file_type === 'selfie') {
      return acc + f.file.parts.length;
    }
    return acc;
  }, 0);
  const IdCardNum = files.reduce((acc, f) => {
    if (f.file_type !== 'selfie') {
      return acc + f.file.parts.length;
    }
    return acc;
  }, 0);
  const numOfFiles = selfiesNum + IdCardNum;

  const handleBtnClick = () => {
    setPreviewDisplay(true);
  };

  return (
    <>
      <Styled.Files disabled={numOfFiles === 0} onClick={handleBtnClick} type="button">
        <Styled.FilesIcon><IconAttachment /></Styled.FilesIcon>
        <Styled.FilesData>
          <Styled.Label>{`${numOfFiles} Attachments`}</Styled.Label>
          <Styled.Value>{`${selfiesNum} selfies, ${IdCardNum} ID cards`}</Styled.Value>
        </Styled.FilesData>
      </Styled.Files>
      {isPreviewDisplayed && (
        <FilesPreview
          isOpen
          closeCb={() => setPreviewDisplay(false)}
          files={files}
        />
      )}
    </>
  );
};

Files.propTypes = {
  files: PropTypes.array,
};

Files.defaultProps = {
  files: [],
};

export default Files;
