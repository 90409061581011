/* eslint-disable no-unused-vars */
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { IconSurvey } from 'shared/icons';
import * as Styled from './styles';

const Surveys = ({
  data,
}) => {
  const completedCount = data.reduce((acc, item) => {
    if (get(item, 'user_questionnaire.finished')) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <Styled.Surveys>
      <Styled.SurveysIcon><IconSurvey width={19} height={20} /></Styled.SurveysIcon>
      <Styled.SurveysData>
        <Styled.Label>{`${data.length} Surveys`}</Styled.Label>
        <Styled.Value>{`${completedCount} / ${data.length} completed`}</Styled.Value>
      </Styled.SurveysData>
    </Styled.Surveys>
  );
};

Surveys.propTypes = {
  data: PropTypes.array,
};

Surveys.defaultProps = {
  data: [],
};

export default Surveys;
