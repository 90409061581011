import styled, { css } from 'styled-components';

export const Sidebar = styled.aside`
  background-color: ${props => props.theme.colors.primary.daintree.daintree50};
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  ${props => {
    if (props.size === 'lg') {
      return css`
        width: 240px;
      `;
    }
    return css`
        width: 48px;
        align-items: center;

        .NavItemText,
        .FooterItemText,
        .EntityName {
          display: none;
        }

        .EntityDetails {
          padding: 13px 8px;
        }
      `;
  }};
`;

export const Toggle = styled.div`
  margin: 17px 15px 25px 15px;
`;

export const ToggleBtn = styled.button`
  appearance: none;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Nav = styled.nav`
  margin-top: 0px;
`;

export const EntityDetails = styled.div`
  display: flex;
  align-items: center;
  padding: 13px 17px 13px 8px;

  svg {
    margin-left: 10px;
  }
`;

export const AdminLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.neutral.white};
  margin-left: 20px;
`;

export const EntityName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: ${props => props.theme.colors.shades.neutral.white};
  margin-left: 8px;
`;

export const EntityLogo = styled.img`
  width: 100%;
  max-width: 32px;
  height: auto;
`;

export const NavItem = styled.div`
  height: 48px;

  a {
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.primary.daintree.daintree05};
    text-decoration: none;
    padding: 13px 17px;
    height: 100%;
    position: relative;

    &.active {
      background-color: ${props => props.theme.colors.primary.daintree.daintree40};

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${props => props.theme.colors.shades.neutral.white};
        position: absolute;
        left: -4px;
      }
    }
  }
`;

export const NavItemText = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-left: 17px;
  position: relative;
`;

export const Footer = styled.div`
  margin-top: auto;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: ${props => props.theme.colors.primary.daintree.daintree40};
  padding: 8px 0;
`;

export const FooterItem = styled.div`
  height: 48px;
`;

export const LogoutBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 13px 17px;
  height: 100%;
  width: 100%;
`;

export const LogoutBtnText = styled.div`
  font-size: 14px;
  line-height: 22px;
  margin-left: 17px;
  color: ${props => props.theme.colors.shades.neutral.white};
`;

export const UnreadCount = styled.span`
  position: absolute;
  margin-left: 2px;
  font-size: 11px;
  top: -5px;
  right: -24px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.danger.danger50};
  font-weight: bold;
`;

export const UnreadCountSmall = styled.span`
  position: absolute;
  margin-left: 2px;
  font-size: 11px;
  top: 0;
  right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.shades.neutral.white};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.danger.danger50};
  font-weight: bold;
`;

export const Version = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  color: ${props => props.theme.colors.shades.neutral.white};
  font-size: 12px;
`;

export const ExpandedVersion = styled.div`
  margin-left: 17px;
`;

export const VersionSmall = styled.div`
  margin: 0 auto;
  font-size: 11px;
`;
