import styled from 'styled-components';

export const UserNotification = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Text = styled.div``;

export const User = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }

  a {
    text-decoration: none;
    display: flex;
  }
`;

export const UserBio = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn60};
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.shades.warm.dawn90};
  }
`;

export const ContactItem = styled.a`
  appearance: none;
  display: flex;
  align-items: center;
  background: transparent;
  cursor: pointer;
  text-decoration: none;
  margin: 0 8px 8px 0;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.primary.daintree.daintree50};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};

  span {
    margin: 0 0 0 10px;
    text-transform: initial;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: auto;
`;

export const Error = styled.div`
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.danger.danger50};
`;

export const Roles = styled.div`
  margin-top: 40px;
`;

export const RolesHeader = styled.div`
  font-size: 12px;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.shades.warm.dawn60};
  text-transform: uppercase;
`;

export const Approved = styled.div`
  margin-top: 20px;
`;
