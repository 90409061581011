import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import { mapUserTopologySummary, getTotalYoc } from 'shared/helpers';
import UserCircles from './UserCircles';

const YocCircles = ({
  userID,
  userClickCb,
  title,
}) => {
  const topologies = useSelector(state => get(state, 'topology.types'));
  const [yocDataCount, setYocDataCount] = useState(1);
  const [yocData, setYocData] = useState([]);
  const [yocDataLoading, setYocDataLoading] = useState(null);

  const getUserYOC = useCallback(() => {
    api.get(`/api/yoc/users/${userID}/connections/summary?page=1&per_page=1000&mode=inviter`)
      .then((res) => {
        const data = get(res, 'data');
        const result = mapUserTopologySummary(data, topologies);
        setYocDataCount(getTotalYoc(data));
        setYocData(result);
        setYocDataLoading(false);
      })
      .catch(() => {
        setYocDataLoading(false);
      });
  }, [userID]);

  useEffect(() => {
    getUserYOC();
  }, [getUserYOC]);

  return (
    <UserCircles
      data={yocData}
      dataCount={yocDataCount}
      dataLoading={yocDataLoading}
      userID={userID}
      title={title}
      userClickCb={userClickCb}
    />
  );
};

YocCircles.propTypes = {
  userID: PropTypes.number.isRequired,
  userClickCb: PropTypes.func,
  title: PropTypes.string,
};

YocCircles.defaultProps = {
  userClickCb: () => {},
  title: '',
};

export default YocCircles;
