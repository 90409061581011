/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const UserCircles = styled.div`
  .Loader {
    margin: 0;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Count = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const CirclesWrapper = styled.ul`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  border-bottom: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  list-style-type: none;

  ${props => {
    if (props.loading) {
      return css`
        border: none;
      `;
    }
  }}
`;

export const CirclesItem = styled.li`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 9px 12px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};
`;

export const CirclesItemBtn = styled.div`
  appearance: none;
  background-color: transparent;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${props => props.theme.colors.shades.warm.dawn20};

    .Button {
      margin: 0;
      padding: 5px 12px;
      padding-bottom: 6px;
      width: 100%;
      max-width: unset;
    }
`;

export const CircleName = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
export const CircleCount = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;
