import { get } from 'lodash';
import api from 'shared/api';
import * as actionTypes from './actionTypes';

// countries actions
const initCountries = (actions) => () => ({
  type: actions.getCountriesInit,
});

const getCountriesError = (actions) => () => ({
  type: actions.getCountriesError,
});

const getCountriesSuccess = (actions) => (res) => ({
  type: actions.getCountriesSuccess,
  countries: res,
});

export const getCountries = () => (dispatch) => {
  dispatch(initCountries(actionTypes)());
  return api.get('/api/user-management/countries').then(
    (res) => dispatch(getCountriesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getCountriesError(actionTypes)()),
  );
};

// currencies actions
const initCurrencies = (actions) => () => ({
  type: actions.getCurrenciesInit,
});

const getCurrenciesError = (actions) => () => ({
  type: actions.getCurrenciesError,
});

const getCurrenciesSuccess = (actions) => (res) => ({
  type: actions.getCurrenciesSuccess,
  currencies: res,
});

export const getCurrencies = () => (dispatch) => {
  dispatch(initCurrencies(actionTypes)());
  return api.get('/api/user-management/currencies').then(
    (res) => dispatch(getCurrenciesSuccess(actionTypes)(get(res, 'data'))),
    () => dispatch(getCurrenciesError(actionTypes)()),
  );
};
