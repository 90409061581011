/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

export const OfferingPage = styled.div`
  background: ${props => props.theme.colors.shades.neutral.white};
  padding: 12px 24px 24px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 920px;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Organization = styled.div`
  font-size: 18px;
  line-height: 32px;
  color: ${props => props.theme.colors.shades.warm.dawn70};

  .Button {
    text-decoration: underline;
    padding: 0;
  }
`;

export const FundingContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const AmountRaised = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.success.success50};
`;

export const AmountSeparator = styled.div`
  margin: 0 4px;
`;

export const AmountGoal = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const Page = styled.div`
  display: flex;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
  }
`;

export const Aside = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 240px;
  margin-left: 24px;

  .TextInput {
    min-width: unset;
  }

  .ProfileCard {
    max-width: 240px;

    @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 100%;
    }
  }

  .Button {
    &:first-child {
      margin-top: 0;

      @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
        margin-top: 10px;
      }
    }
  }

  .ProfileCard {
    margin: 20px 0;
  }

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    width: 100%;
    max-width: unset;
    margin-left: 0;
  }
`;

export const DetailsContainer = styled.div`
  padding: 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const DetailsItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 48%;
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DetailLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const DetailValue = styled.div`
  font-size: 14px;
  line-height: 20px;
  word-break: break-all;
  color: ${props => props.theme.colors.shades.warm.dawn70};
`;

export const Data = styled.div`
  width: 100%;

  .Section {
    margin-top: 16px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  height: 368px;
  margin-top: 2px;
  margin-bottom: 10px;
  position: relative;
  
  @media screen and (max-width: 1080px) {
    min-height: 300px;
    max-height: 300px;
    height: unset;
  }

  &:hover {
    .BannerBtn {
      display: flex;
    }
  }
`;

export const BannerBtn = styled.button`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  border: none;
  background: rgb(255 255 255 / 50%);

  ${props => {
    if (!props.hasSelectedImage) {
      return css`
        display: flex;
      `;
    }
  }}
`;

export const BannerBtnText = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  padding: 4px 20px;
  min-height: 32px;
  background: ${props => props.theme.colors.primary.flamingo.flamingo50};
  color: ${props => props.theme.colors.shades.neutral.white};
`;

export const BannerImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 368px;
  object-fit: cover;

  @media screen and (max-width: 1080px) {
    min-height: 300px;
    max-height: 300px;
  }
`;

export const BannerImgDefault = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary.daintree.daintree05};
`;

export const ImagesList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .fullscreen-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  ${props => {
    if (!props.multiImages) {
      return css`
        .fullscreen-toggle {
          display: none;
        }
      `;
    }
  }}
`;

export const ImageDeleteBtn = styled.button`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 50%);
  border: none;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 0;
  right: 0;
`;

export const ImageBtn = styled.div`
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 80px;
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  
  img {
    width: 100%;
    height: auto;
    max-height: 80px;
    object-fit: cover;
  }
`;

export const AddImageBtn = styled.button`
  appearance: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 80px;
  width: 120px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn30};
`;

export const AddImageBtnText = styled.div`
  margin-top: 5px;
`;

export const AccentCardsList = styled.div`
  margin-top: 8px;
  display: grid;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, 210px);

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
`;

export const AccentCard = styled.div`
  padding: 16px 24px;
  background: ${props => props.theme.colors.shades.neutral.white};
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.shades.warm.dawn20};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 220px;
  margin-bottom: 6px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    max-width: 100%;
  }
`;

export const AccentLabel = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;

export const AccentValue = styled.div`
  font-size: 24px;
  line-height: 36px;
  color: ${props => props.theme.colors.shades.warm.dawn90};
`;
