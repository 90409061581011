import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IconLocation, IconNoProfilePicture, IconEdit } from 'shared/icons';
import { Button } from 'shared/components';
import ChangePasswordForm from '../ChangePasswordForm';
import UserProfileForm from '../UserProfileForm';
import ChangeProfilePictureForm from '../ChangeProfilePictureForm';
import * as Styled from './styles';

const ProfileCard = ({
  data,
  isAuth,
  refetchProfile,
  showActions,
  showLink,
}) => {
  const [showPasswordForm, setPasswordFormDisplay] = useState(false);
  const [isEditUserFormDisplayed, setEditUserFormDisplay] = useState(false);
  const [isChangeImageDisplayed, setChangeImageDisplayed] = useState(false);

  const backgroundImg = get(data, 'background_image') || '';
  const profilePicture = get(data, 'profile_picture') || '';
  const name = get(data, 'full_name') || get(data, 'user_name') || 'N/A';

  const country = get(data, 'country_of_residence') || '';
  const countries = useSelector(state => get(state, 'investing.countries'));
  const countryOfResidence = countries.find(c => get(c, 'id') === country);
  const city = get(data, 'city') || '';
  const address = `${city}${country ? `, ${get(countryOfResidence, 'name')}` : ''}`;

  return (
    <Styled.ProfileCard className="ProfileCard">
      <Styled.ProfileCardInner>
        <Styled.Banner>
          {backgroundImg ? (
            <Styled.BannerImg src={backgroundImg} alt="profile background" />
          ) : (
            <Styled.BannerImgDefault />
          )}
        </Styled.Banner>
        <Styled.ProfilePictureWrapper>
          {profilePicture ? (
            <Styled.ProfilePicture src={profilePicture} alt="profile picture" />
          ) : (
            <IconNoProfilePicture width={80} height={80} />
          )}
          {isAuth && (
            <Styled.EditBtn
              type="button"
              onClick={() => setChangeImageDisplayed(true)}
              className="EditBtn"
            >
              <IconEdit />
            </Styled.EditBtn>
          )}
        </Styled.ProfilePictureWrapper>
        <Styled.CardInner>
          <Styled.Name>
            {showLink ? <Link to={`/users/${get(data, 'user_id')}`}>{name}</Link> : name}
          </Styled.Name>
          <Styled.Title>
            {get(data, 'title') || 'N/A'}
          </Styled.Title>
          <Styled.Address>
            <IconLocation />
            <Styled.AddressText>{address}</Styled.AddressText>
          </Styled.Address>
          {showActions && (
            <>
              {isAuth && (
                <Button variant="outlined" size="small" onClick={() => setEditUserFormDisplay(true)}>
                  Edit profile
                </Button>
              )}
              {isAuth && (
                <Button variant="outlined" size="small" onClick={() => setPasswordFormDisplay(true)}>
                  Change password
                </Button>
              )}
            </>
          )}
        </Styled.CardInner>
      </Styled.ProfileCardInner>
      {showPasswordForm && (
        <ChangePasswordForm
          isOpen={showPasswordForm}
          onClose={() => setPasswordFormDisplay(false)}
        />
      )}
      {isEditUserFormDisplayed && (
        <UserProfileForm
          isOpen={isEditUserFormDisplayed}
          closeCb={() => setEditUserFormDisplay(false)}
          user={data}
          refetchUser={refetchProfile}
        />
      )}
      {isChangeImageDisplayed && (
        <ChangeProfilePictureForm
          isOpen={isChangeImageDisplayed}
          closeCb={() => setChangeImageDisplayed(false)}
          confirmCb={() => {
            refetchProfile();
            setChangeImageDisplayed(false);
          }}
          user={data}
        />
      )}
    </Styled.ProfileCard>
  );
};

ProfileCard.propTypes = {
  data: PropTypes.object.isRequired,
  isAuth: PropTypes.bool,
  refetchProfile: PropTypes.func,
  showActions: PropTypes.bool,
  showLink: PropTypes.bool,
};

ProfileCard.defaultProps = {
  isAuth: false,
  showActions: true,
  showLink: false,
  refetchProfile: () => {},
};

export default ProfileCard;
