import styled from 'styled-components';

export const Dashboard = styled.div`
  width: 100%;
  padding: 0px 20px 24px;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 0;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-around;

  @media screen and (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 24px 20px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;
